<div
	[ngClass]="{
		'salesforce-badge-container-success': perfil == badgeType.SUCCESS,
		'salesforce-badge-container-sales-farm': perfil == badgeType.SALESFARM,
		'salesforce-badge-container-field-sales': perfil == badgeType.FIELDSALES,
		'salesforce-badge-container-sales-rep': perfil === badgeType.SALESREP,
		'salesforce-badge-container-account': perfil === badgeType.ACCOUNT,
		'salesforce-badge-container-labor-union': perfil === badgeType.LABORAL,
		'salesforce-badge-container-employee-union': perfil === badgeType.PATRONAL,
		'salesforce-badge-container-produtos-comissoes': perfil === badgeType.COMISSOES,
		'salesforce-badge-container-danger': perfil === badgeType.DANGER,
		'salesforce-badge-container-inactive': perfil === badgeType.INACTIVE,
		'salesforce-badge-container-default': isDefault
	}"
	class="rounded-pill py-2 font-weight-bold">
	<span class="col-md-8">{{ valor || perfil | titlecase }}</span>
</div>
