import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from '@angular/forms'
import {NgSelectComponent} from '@ng-select/ng-select'
import {Subscription} from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import {SelectListItem} from 'src/app/model/interfaces/select-item-list'
const DEBOUNCE_TIME_IN_MILLISECONDS = 500

@Component({
	selector: 'simple-select-v2',
	templateUrl: './simple-select-v2.component.html',
	styleUrls: [
		'./simple-select-v2.component.scss',
		'../../../../vendor/libs/ng-select/ng-select.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss',
	],
	// providers: [
	// 	{
	// 		provide: NG_VALUE_ACCESSOR,
	// 		useExisting: SimpleSelectV2Component,
	// 		multi: true,
	// 	},
	// ],
})
export class SimpleSelectV2Component
	implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
	constructor(
		public ngControl: NgControl,
		private cd: ChangeDetectorRef) {
		ngControl.valueAccessor = this
	}

	@ViewChild('simpleSelect') simpleSelect: NgSelectComponent

	selectForm: FormGroup

	@Input() formControl: FormControl

	@Input() multiple = false
	@Input() closeOnSelect = true
	@Input() hideSelected = true
	@Input() clearable = true
	@Input() virtualScroll = false
	@Input() searchable = false

	@Input() placeholder = ''
	@Input() notFoundText = ''
	@Input() clearAllText = ''
	@Input() typeToSearchText = ''

	@Input() name = ''
	@Input() bindLabel = ''
  @Input() alternativeLabel = ''
	@Input() bindValue = ''
	@Input() selectUpperLabel = ''
	@Input() labelNoResultFound = ''
	@Input() iconClass = ''
  @Input() useEllipsis = false

	@Input() compareFn = (a,b) => a.value === b

	@Input() items: any[] = []

	@Input() loading = false
	@Input() isReadonly = false
	@Input() isDisabled = false
	@Input() submitted = false
	@Input() isInvalid = false

	@Output() OnClear: EventEmitter<any> = new EventEmitter()
	@Output() OnScrollToEnd: EventEmitter<any> = new EventEmitter()
	@Output() OnScroll: EventEmitter<any> = new EventEmitter()
	@Output() OnSelect: EventEmitter<any> = new EventEmitter()
	@Output() OnSearch: EventEmitter<any> = new EventEmitter()

	@Input() returnFullValue: boolean = false;

	selectTypeahead: EventEmitter<any> = new EventEmitter()
	subValueChangesSelect: Subscription

	selectedItems: any[]

	value: any
	@Input() disabled = false
	onChange: any = () => {}
	onTouched: any = () => {}

	@Input() reloadItems = (term): SelectListItem[] => {
		return []
	}

	ngOnChanges(changes: SimpleChanges): void {
	}

	ngOnDestroy(): void {}

	ngAfterViewInit(): void {

	}

	ngOnInit(): void {
		this.selectTypeahead
		.pipe(
			debounceTime(DEBOUNCE_TIME_IN_MILLISECONDS),
			switchMap(term => this.reloadItems(term)),
		)
		.subscribe(
			(items: any) => {
				this.cd.markForCheck() /**/
			},
			err => {
				this.cd.markForCheck() /**/
			},
		)
	}

	writeValue(value: any): void {
		this.value = value
	}

	registerOnChange(fn: any): void {
		this.onChange = fn
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	onChangeSelection(selected: any) {
		if (selected) {
			if(this.returnFullValue) {
				this.OnSelect.emit(selected)
				this.onChange(selected)
			} else {
				const {value} = selected
				this.OnSelect.emit(value)
                this.onChange(value)
			}
		}
	}

	clearSelection() {
		this.onChange(null)
		this.OnClear.emit()
	}

	scrollToEnd(searchTerm: string) {
		this.OnScrollToEnd.emit({searchTerm})
	}

	onScroll({end}, searchTerm?: string) {
		this.OnScroll.emit({end, searchTerm})
	}
}
