import {DOCUMENT} from '@angular/common'
import {AfterViewInit, Component, Inject, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-index-agiben',
	templateUrl: './index-agiben.component.html',
	styleUrls: ['./index-agiben.component.scss']
})
export class IndexAgibenComponent implements AfterViewInit {
	isExpanded3 = false

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute,
		@Inject(DOCUMENT)
		private document: Document
	) {
		// TODO: Realizar gestão da URL de redirecionamento a partir de variável de ambiente e também verificação do ambiente (PROD ou DEV)
		// TODO2: Utilizar modulefederation para implantar a página implicitamente no código
		this.document.location.href = 'https://institucional.agiben.com.br'
	}

	ngAfterViewInit(): void {
		this.route.fragment.subscribe(fragment => {
			document.getElementById(fragment)?.scrollIntoView({behavior: 'smooth', block: 'start'})
		})
	}

	open(content, options = {}) {
		this.modalService.open(content, options).result.then(
			result => {
				console.log(`Closed with: ${result}`)
			},
			reason => {
				console.log(`Dismissed ${this.getDismissReason(reason)}`)
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return `with: ${reason}`
		}
	}

	scrollToSection(nomeDiv: any, page: any) {
		if (page === 'Home') {
			document.getElementById(nomeDiv)?.scrollIntoView({behavior: 'smooth', block: 'start'})
		} else if (page === 'Beneficios') {
			nomeDiv
				? this.router.navigate(['/beneficios'], {fragment: nomeDiv})
				: this.router.navigate(['/beneficios'])
		}
	}
}
