<form [formGroup]="formSegundaInclusao">
	<div class="form-row">
		<div class="header d-flex justify-content-between col-md-12">
			<h5 class="card-title">{{ 'vidas.detail.dados_gerais' | translate }}</h5>
		</div>
	</div>
	<div class="form-row">
		<div class="col">
			<simple-select name="vida_tipo" selectUpperLabel="{{ 'vidas.detail.tipo_vida' | translate}} *"
				placeholder="{{ 'vidas.detail.select_opcao' | translate}}" [items]="opcoesTipoVida"
				formControlName="vida_tipo"
				bindLabel="descricao" bindValue="value" ngDefaultControl>
			</simple-select>
		</div>
	</div>

	<div *ngIf="getFormSegundaInclusao.vida_tipo.value">
		<div class="form-row mt-2">
			<div class="col">
				<simple-select *ngIf="getFormSegundaInclusao.cli_codigoassociados4e.value" name="cli_codigoassociados4e"
					selectUpperLabel="{{ 'vidas.detail.titular_vinculado' | translate}} *"
					placeholder="{{ 'vidas.placeholders.pesquisa_nome_cpf' | translate}}" [items]="opcoesTitulares"
					(onSelect)="titularSelecionado($event)" [loading]="buscandoColaboradoresTitulares"
					formControlName="cli_codigoassociados4e" bindLabel="descricao"
					bindValue="value" [reloadItems]="listarBeneficiarioPorNomeOuCpf" ngDefaultControl>
				</simple-select>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input id="vida_cpf" formControlName="vida_cpf"
					placeholder="{{ 'vidas.detail.informe_cpf_dependente' | translate}}" mask="CPF_CNPJ" label="CPF *"
					ngDefaultControl>
				</agisales-input>

				<div *ngIf="
						getFormSegundaInclusao.vida_cpf?.touched &&
						getFormSegundaInclusao.vida_cpf.errors?.cpfInvalid
					" class="mt-2 error">
					CPF {{ 'vidas.modal_vida_avulsa.invalido' | translate }}
				</div>

				<div *ngIf="buscandoCPF" class="mt-2 warning">{{ 'vidas.modal_vida_avulsa.verificando' |
					translate }} CPF...</div>
			</div>
			<div class="col-6 padding-top-3" *ngIf="dadosInclusaoVidas.tipoVida === tipoVidaEnum.DEPENDENTE">
				<simple-select name="cli_grauparentesco"
					selectUpperLabel="{{ 'vidas.detail.grau_parentesco' | translate}} *"
					placeholder="{{ 'vidas.detail.select_opcao' | translate}}" [items]="opcoesGrauParentesco"
					formControlName="cli_grauparentesco" [searchable]="false" bindLabel="descricao" bindValue="value"
					ngDefaultControl>
				</simple-select>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col">
				<agisales-input id="vida_nome" formControlName="vida_nome"
					placeholder="{{ 'vidas.placeholders.insira_nome_completo_dependente' | translate }}"
					label="{{ 'vidas.detail.nome_completo' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col">
				<agisales-input id="vida_nomemae" formControlName="vida_nomemae"
					placeholder="{{ 'vidas.placeholders.insira_nome_mae_dependente' | translate }}"
					label="{{ 'vidas.detail.nome_completo_mae' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input mask="d0/M0/0000" id="vida_nascimento" formControlName="vida_nascimento"
					placeholder="Ex: 01/01/2000" label="{{ 'vidas.detail.data_nascimento' | translate}} *"
					ngDefaultControl>
				</agisales-input>
			</div>

			<div class="col-6 padding-top-3">
				<simple-select name="opcoesTipoSexo" selectUpperLabel="{{ 'vidas.detail.sexo' | translate }} *"
					[items]="opcoesTipoSexo" placeholder="{{ 'vidas.detail.select_opcao' | translate}}"
					formControlName="vida_sexo" bindLabel="descricao" bindValue="value" ngDefaultControl>
				</simple-select>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input id="vida_telefone" formControlName="vida_telefone" mask="(00)00000-0000"
					placeholder="{{ 'vidas.modal_vida_avulsa.informe_telefone_contato' | translate}}"
					label="{{ 'vidas.detail.telefone' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>

			<div class="col-6">
				<agisales-input id="vida_email" formControlName="vida_email"
					placeholder="{{ 'vidas.modal_vida_avulsa.informe_email_dependente' | translate}}" label="E-mail"
					ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="form-group col">
				<label class="form-label mb-1 font-weight-normal">* {{ 'vidas.detail.campo_obrigatorio' |
					translate }}</label>
			</div>
		</div>
	</div>
</form>


<default-swal #swalBeneficiarioJaCadastrado confirmButtonText="{{'vidas.modal_vida_avulsa.entendi' | translate}}"
	icon="warning" title="{{'vidas.modal_vida_avulsa.beneficiario_already_added' | translate}}" text=""
	(confirmCallback)="fecharSwalBeneficiarioJaCadastrado()" (cancelCallback)="fecharSwalBeneficiarioJaCadastrado()">
</default-swal>