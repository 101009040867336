import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInCalendarMonths, parse } from "date-fns";
import { gerarDateBaseadoEmMesAno, validateCNPJ, validateCPF } from './util';

export function createPasswordStrengthValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value

		if (!value) {
			return null
		}

		const hasUpperCase = /[A-Z]+/.test(value)

		const hasLowerCase = /[a-z]+/.test(value)

		const hasNumeric = /[0-9]+/.test(value)

		const passwordValid = hasUpperCase && hasLowerCase && hasNumeric

		return !passwordValid ? {passwordStrength: true} : null
	}
}

export function cnpjValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value

		if (!value) {
			return null
		}

		const valid = validateCNPJ(value)

		return valid ? null : {cnpjInvalid: true}
	}
}

export function cpfValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value

		if (!value || value.length < 11) {
			return null
		}

		const valid = validateCPF(value)

		return valid ? null : {cpfInvalid: true}
	}
}

export function validateMinimumDateBasedOnMonthYear(date: Date, errorMessage: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value
		let valid = false
		const monthYearRegex: RegExp = new RegExp(/\d{2}\/\d{4}/)

		if (!monthYearRegex.test(value)) {
			return {minimumDate: true}
		}

		const [mes, ano] = value.split('/')
		const dataAVerificar = gerarDateBaseadoEmMesAno(mes, ano)

        date.setHours(0,0,0,0);
        dataAVerificar.setHours(0,0,0,0);

        dataAVerificar.getTime() > date.getTime()? valid = true : valid = false;
        return valid ? null : {minimumDate: true, message: errorMessage};
    }
}

export function emailValidator(errorMessage: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value
		const emailRegex: RegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

		if (value) {
			if (!emailRegex.test(value)) {
				return {email: true, errorMessage}
			}
		}

		return null
	}
}

export function phoneValidator(errorMessage: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value
		const phoneRegex: RegExp = new RegExp(/^\d{2}9?\d{8}?$/)

		if (value) {
			if (!phoneRegex.test(value)) {
				return {phone: true, errorMessage}
			}
		}

		return null
	}
}

export function ValidateCPF(control: AbstractControl) {
  const cpf: string = control.value?.toString().trim() || '';

  if (!cpf) {
    return null;
  }

  const cpfDigits = cpf.replace(/[^\d]/g, '');

  if (cpfDigits.length !== 11) {
    return { cpfInvalid: true };
  }

  if (/^(\d)\1{10}$/.test(cpfDigits)) {
    return { cpfInvalid: true };
  }

  const calcVerifierDigits = (cpfDigits: string) => {
    let sum = 0;
    let remainder: number;

    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpfDigits[i], 10) * (10 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;

    if (remainder !== parseInt(cpfDigits[9], 10)) return false;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpfDigits[i], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;

    return remainder === parseInt(cpfDigits[10], 10);
  };

  if (!calcVerifierDigits(cpfDigits)) {
    return { cpfInvalid: true };
  }

  return null;
}

export function ValidateFullName(control: AbstractControl) {
  const fullName: string = control.value?.trim() || '';
  const names = fullName.split(' ');

  if (names.length < 2 && fullName.length > 0) {
    return { fullName: true };
  }

  const specialCharRegex = /[^a-zA-ZÀ-ÿ\s]/;

  if (specialCharRegex.test(fullName)) {
    return { specialCharacters: true };
  }

  const abbreviationRegex = /(^|\s)([a-zA-Z]\.\s|[a-zA-Z]{1}\s)/;
  if (abbreviationRegex.test(fullName)) {
    return { abbreviations: true };
  }
  return null;
}

export function ageValidator(minAge: number, maxAge: number) {

  return (control: AbstractControl) => {
    const birthday = control.value;
    const birthDate = parse(birthday, 'dd/MM/yyyy', new Date());
    const today = new Date();

    if (birthDate > today) {
      return { futureDate: true };
    }

    const age = today.getFullYear() - birthDate.getFullYear();
    birthDate.setFullYear(today.getFullYear());
    const monthDifference = differenceInCalendarMonths(today, birthDate);

    if (age < minAge || (age === minAge && monthDifference < 0)) {
      return { minAge };
    }
    if (age > maxAge || (age === maxAge && monthDifference < 0)) {
      return { maxAge };
    }

    return null;
  };
}

export function ValidatePhoneNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numero = control.value;

    if (!numero) {
      return null;
    }

    const numeroLimpo = numero.replace(/[()\s-]/g, '');
    const regex = /^\d{11}$/;

    if (!regex.test(numeroLimpo)) {
      return { invalidNumber: true };
    }

    const ddd = numeroLimpo.substring(0, 2);
    const prefixo = numeroLimpo.substring(2, 3);

    const dddsValidos = [
      "11", "21", "31", "41", "51", "61", "71", "81", "91", "48", // São Paulo, Rio de Janeiro, Minas Gerais, Espírito Santo, etc.
      "27", "28", // Espírito Santo
      "32", "33", "34", "35", "37", "38", // Minas Gerais
      "22", "24", // Rio de Janeiro
      "12", "13", "14", "15", "16", "17", "18", "19", // São Paulo
      "53", "54", "55", // Rio Grande do Sul
      "61", // Distrito Federal/Goiás
      "82", // Alagoas
      "73", "74", "75", "77", // Bahia
      "85", "88", // Ceará
      "98", "99", // Maranhão
      "83", // Paraíba
      "81", "87", // Pernambuco
      "86", "89", // Piauí
      "84", // Rio Grande do Norte
      "79", // Sergipe
      "68", // Acre
      "96", // Amapá
      "92", "97", // Amazonas
      "91", "93", "94", // Pará
      "69", // Rondônia
      "95", // Roraima
      "63" // Tocantins
    ];

    const prefixosValidos = ["9"];

    if (!dddsValidos.includes(ddd)) {
      return { invalidDDD: { value: ddd } };
    }

    if (!prefixosValidos.includes(prefixo)) {
      return { invalidPrefixo: { value: prefixo } };
    }
    return null;
  };
}

export function ValidateTelephoneNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numero = control.value;

    if (!numero) {
      return null;
    }

    const numeroLimpo = numero.replace(/[()\s-]/g, '');

    const regex = /^\d{10}$/;

    if (!regex.test(numeroLimpo)) {
      return { invalidNumberTel: true };
    }

    const ddd = numeroLimpo.substring(0, 2);
    const prefixo = numeroLimpo.substring(2, 3);

    const dddsValidos = [
      "11", "21", "31", "41", "51", "61", "71", "81", "91", // São Paulo, Rio de Janeiro, Minas Gerais, Espírito Santo, etc.
      "27", "28", // Espírito Santo
      "32", "33", "34", "35", "37", "38", // Minas Gerais
      "22", "24", // Rio de Janeiro
      "12", "13", "14", "15", "16", "17", "18", "19", // São Paulo
      "53", "54", "55", // Rio Grande do Sul
      "61", // Distrito Federal/Goiás
      "82", // Alagoas
      "73", "74", "75", "77", // Bahia
      "85", "88", // Ceará
      "98", "99", // Maranhão
      "83", // Paraíba
      "81", "87", // Pernambuco
      "86", "89", // Piauí
      "84", // Rio Grande do Norte
      "79", // Sergipe
      "68", // Acre
      "96", // Amapá
      "92", "97", // Amazonas
      "91", "93", "94", // Pará
      "69", // Rondônia
      "95", // Roraima
      "63" // Tocantins
    ];
    const prefixosValidos = ["2", "3", "4", "5"];

    if (!dddsValidos.includes(ddd)) {
      return { invalidDDDTel: { value: ddd } };
    }

    if (!prefixosValidos.includes(prefixo)) {
      return { invalidPrefixoTel: { value: prefixo } };
    }

    return null; // Número válido
  };
}


export function ValidateEmail(control: AbstractControl) {
  const email: string = control.value?.trim() || '';

  if (!email) {
    return null;
  }

  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
  if (!regex.test(email)) {
    return { invalidEmail: true };
  }

  const specialCharRegex = /[^a-zA-Z0-9_.-@]/;
  if (specialCharRegex.test(email)) {
    return { specialCharactersEmail: true };
  }

  return null;
}
