import { CurrencyPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { OptionsUpload } from 'src/app/model/interfaces/options-upload'
import { TypeFile } from 'src/app/others-component/upload-popup/type-file'
import { UploadPopupComponent } from 'src/app/others-component/upload-popup/upload-popup.component'
import { FileUploadPopup } from '../../migrar-plano.interface'

@Component({
	selector: 'app-migrar-plano-form-foto',
	templateUrl: './migrar-plano-form-foto.component.html',
	styleUrls: ['./migrar-plano-form-foto.component.scss'],
	providers: [CurrencyPipe],
})
export class MigrarPlanoFormFotoComponent implements OnInit {

	loading: boolean = false
	uploadImagemRealizado: boolean = false
	imagemEnviada: boolean = false
	@Input() urlFicha: string = ''
	@Input() arquivosEnviados: FileUploadPopup[] = []
	@Input() vidasNome: string = ''
	@Input() nomeArquivo: string = ''

	constructor(private ngbModal: NgbModal,
		public translateService: TranslateService) { }

	ngOnInit() {
		this.arquivosEnviados = this.arquivosEnviados || []
		this.urlFicha = this.urlFicha || null
	}

	removerArquivo(item) {
		const index = this.arquivosEnviados.findIndex(arquivo => arquivo === item)
		if (index !== -1) {
			this.arquivosEnviados.splice(index, 1)
		}
	}

	baixarArquivo(item) {
		const url = item.dataURL ? item.dataURL : this.urlFicha
		const link = document.createElement('a')
		link.href = url
		link.target = '_blank'
		link.download = item.filename
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	openWebcam() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showWebcam: true
		}

		this.openUploadPopup(optionsUpload)
	}

	openUploadPopup(optionsUpload: OptionsUpload) {
		const options: NgbModalOptions = { windowClass: 'modal-lg animate' }

		const modal = this.ngbModal.open(UploadPopupComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)

		modal.result.then(result => {
			if (result && result.dataURL) {
				this.arquivosEnviados = [{ filename: optionsUpload.fileName, size: result?.size }]
				this.imagemEnviada = result.dataURL
				this.urlFicha = `https://dest-clinfluencer-imagens.s3.amazonaws.com/${optionsUpload.folders}/${optionsUpload.fileName}`
				this.uploadImagemRealizado = true
			}
		})
	}

	openDragdrop() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showDragdrop: true
		}
		this.openUploadPopup(optionsUpload)
	}
}
