import {Injectable} from '@angular/core'
import {environment} from 'src/environments/environment'
import {ReceitaFederalDTO} from '../model/receita-federal/receita-federal-dto'
import {PostCadastroEmpresaSindicatoDTO} from '../model/cct/post-cadastro-empresa-sindicato.dto'
import {HttpClient, HttpParams} from '@angular/common/http'
import {AuthService} from './auth/auth.service'
import {Observable} from 'rxjs'
import {ListaMotivoCancelamentoDTO} from '../model/gestao-agenda/lista-motivo-cancelamento-dto'

@Injectable({
	providedIn: 'root',
})
export class PublicoService {
	urlServerAPINest: any = environment.API_URL_NEST

	constructor(
		private http: HttpClient,
		private authService: AuthService,
	) {}

	async getInformacoesEmpresaRF(cnpj: string): Promise<ReceitaFederalDTO | undefined> {
		const urlAPI = this.urlServerAPINest + `publica/rfb/${cnpj}`

		try {
			return await this.http.get<ReceitaFederalDTO>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getInformacoesEmpresaRF')
		}
	}

	async getInformacoesCCT(idCCT: number): Promise<any> {
		try {
			return await this.http.get<any>(`${this.urlServerAPINest}publica/cct/${idCCT}`).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getInformacoesCCT')
		}
	}

	async cadastroEmpresaSindicato(body: PostCadastroEmpresaSindicatoDTO, gfip: any): Promise<any> {
		const formData: FormData = new FormData()

		Object.keys(body).forEach(key => {
			if (body[key]) {
				if (body[key] instanceof Object || body[key] instanceof Array) {
					formData.append(key, JSON.stringify(body[key]))
				} else {
					formData.append(key, body[key])
				}
			}
		})

		if (gfip) {
			formData.append('gfip', gfip, gfip?.name)
		}

		try {
			return await this.http
				.post<any>(`${this.urlServerAPINest}publica/cct-cadastrar-empresa-sindicato`, formData)
				.toPromise()
		} catch (error) {
			this.authService.handleError(error, 'postCadastrarEmpresaSindicato')
		}
	}

	async getInformacoesCCTPeloPaf(paf: string): Promise<any> {
		try {
			return await this.http.get<any>(`${this.urlServerAPINest}publica/paf/${paf}`).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getInformacoesCCTPeloPaf')
		}
	}

	async getInfoByPaf(paf: string) {
		try {
			return await this.http.get<any>(`${this.urlServerAPINest}publica/info/paf/${paf}`).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getInfoByPaf')
		}
	}

	async buscarCEP(cep: string): Promise<any> {
		let retorno = null
		const params: HttpParams = new HttpParams().set('dontIntercept', 'true')

		const options = {
			params,
		}

		try {
			retorno = await this.http.get(`https://viacep.com.br/ws/${cep}/json`, options).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'buscarCEP')
		}

		return retorno
	}

	async listarMotivosCancelamentoCompromisso(): Promise<ListaMotivoCancelamentoDTO[]> {
		try {
			return await this.http
				.get<ListaMotivoCancelamentoDTO[]>(
					`${this.urlServerAPINest}publica/listar-motivos-cancelamento`,
				)
				.toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarMotivosCancelamentoCompromisso')
		}
	}
}
