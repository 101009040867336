import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-inclusao-vida-avulsa-nova-inclusao',
	templateUrl: './inclusao-vida-avulsa-nova-inclusao.component.html',
	styleUrls: ['./inclusao-vida-avulsa-nova-inclusao.component.scss']
})
export class InclusaoVidaAvulsaNovaInclusaoComponent {

	@Input() nomeTitular
}
