import {Injectable} from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class BlipService {
	blipChatFunction: any
	constructor() {}
	openBlip() {
		if (this.blipChatFunction) {
			this.blipChatFunction.toogleChat()
		} else {
			this.blipChatFunction = (window as any).blipChat()
		}
	}

	checkIfHasBlipChatOpened() {
		return this.blipChatFunction
	}

	setInitialMessage(msg: string) {
		const spanElement = document.createElement('span')
		spanElement.setAttribute('id', 'content-msg-atendimento')
		spanElement.style.visibility = 'hidden'
		spanElement.innerHTML = msg
		document.body.appendChild(spanElement)
	}
}
