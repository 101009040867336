<div class="form-column mb-2 box-send-file">
	<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
		<div class="mt-4 d-flex flex-column justify-content-center align-items-center">
			<p class="custom-font-size d-flex flex-column justify-content-center align-items-center box-send-file-tx">
				{{'vidas.modal_vida_avulsa.envie_foto_ficha' | translate }}
				<br />
				<strong>
					{{vidasNome}}
				</strong>
			</p>
		</div>
		<div class="media-body ml-3">
			<div ngbDropdown class="d-inline-flex">
				<button type="button" class="btn btn-dark btn-lg box-send-file-btn" ngbDropdownToggle>
					{{ 'vidas.modal_vida_avulsa.enviar_foto' | translate }}
				</button>
				<div ngbDropdownMenu>
					<a class="dropdown-item" href="javascript:void(0)" (click)="openWebcam()">
						{{ 'vidas.modal_vida_avulsa.tirar_foto' | translate }}
					</a>
					<a class="dropdown-item" href="javascript:void(0)" (click)="openDragdrop()">
						{{ 'vidas.modal_vida_avulsa.enviar_foto' | translate }}
					</a>
				</div>
			</div>
		</div>

		<div class="table-responsive margin-table-arquivos" *ngIf="arquivosEnviados.length">
			<table class="table">
				<thead>
					<tr>
						<th>{{ 'vidas.table.nome' | translate}}</th>
						<th>{{ 'vidas.table.tamanho' | translate}}</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let arquivo of arquivosEnviados">
						<td>{{ arquivo.filename }}</td>
						<td>{{ arquivo.size | formatarBytes }}</td>
						<td>
							<a style="cursor: pointer" aria-hidden="true" (click)="baixarArquivo(arquivo)">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M15.59 10.09L13 12.67V3H11V12.67L8.41 10.09L7 11.5L12 16.5L17 11.5L15.59 10.09ZM19 19V12H21V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V12H5V19H19Z"
										fill="#222222" />
								</svg>
							</a>
						</td>
						<td>
							<a style="cursor: pointer" aria-hidden="true" (click)="removerArquivo(arquivo)">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
										fill="#222222" />
								</svg>
							</a>
						</td>
					</tr>
					<tr>
						<td><br /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>