<default-header-title (onDismiss)="dismiss()" [tituloModal]="tituloModal"></default-header-title>

<div class="modal-body inclusao-vida-modal-height">
	<div *ngIf="currentStep.getValue() === migracaoPlanoPopupStepEnum.STEP_FORM_INFORMACOES_VIDA">
		<app-migrar-plano-form-vida [formInformacoesVida]="formInformacoesVida"
			[cpfMigracaoBeneficiario]="cpfMigracaoBeneficiario"
			(onCarregarPlanoEmpresaFromFormVidas)="carregarDadosPlanoEmpresaFromFormVidas($event)"
			#migrarPlanoFormVida></app-migrar-plano-form-vida>
	</div>
	<div *ngIf="currentStep.getValue() === migracaoPlanoPopupStepEnum.STEP_SELECAO_PRODUTO">
		<app-migrar-plano-form-plano #migrarPlanoFormPlano [listaProdutos]="listaProdutos"
			[formMigrarPlano]="formMigrarPlano"
			[idPlanoBeneficiario]="idPlanoBeneficiario"></app-migrar-plano-form-plano>
	</div>
	<div *ngIf="currentStep.getValue() === migracaoPlanoPopupStepEnum.STEP_ENVIO_FOTO">
		<app-migrar-plano-form-foto #migrarPlanoFormFoto [nomeArquivo]="this.formInformacoesVida?.controls.nome.value"
			[vidasNome]="this.formInformacoesVida?.controls.nome.value" [arquivosEnviados]="arquivosEnviados"
			[urlFicha]="urlFicha"></app-migrar-plano-form-foto>
	</div>
</div>

<div class="modal-footer migrar-plano-popup-footer">
	<div>
		<div class="d-flex col-12 justify-content-end">
			<agi-button class="pl-2 btn-size"
				*ngIf="currentStep.getValue() !== migracaoPlanoPopupStepEnum.STEP_FORM_INFORMACOES_VIDA"
				cssClass="OUTLINE" [label]="labelVoltar" (onClick)="voltarStep()">
			</agi-button>
			<agi-button class="pl-2 btn-size" *ngIf="
					currentStep.getValue() !== migracaoPlanoPopupStepEnum.STEP_ENVIO_FOTO &&
					currentStep.getValue() !== migracaoPlanoPopupStepEnum.STEP_NOVA_SELECAO_PRODUTO" [label]="labelAvancar"
				[disabled]="desabilitarBotaoAvancar()" (onClick)="avancarStep()">
			</agi-button>
			<agi-button class="pl-2 btn-size" *ngIf="
					currentStep.getValue() === migracaoPlanoPopupStepEnum.STEP_ENVIO_FOTO ||
					currentStep.getValue() === migracaoPlanoPopupStepEnum.STEP_NOVA_SELECAO_PRODUTO" [label]="labelAvancar"
				[disabled]="desabilitarBotaoAvancar()" (onClick)="salvarMigracaoPlano()" [loading]="loading">
			</agi-button>

			<default-swal #swalPlanoMigradoComSucesso confirmButtonText="{{'vidas.modal_migrar.entendi' | translate}}"
				icon="success" title="{{'vidas.modal_migrar.migrar_plano_success_title' | translate}}" text=""
				(confirmCallback)="fecharSwalPlanoMigradoComSucessoOuNao()"
				(cancelCallback)="fecharSwalPlanoMigradoComSucessoOuNao()">
			</default-swal>
			<default-swal #swalPlanoNaoMigrado confirmButtonText="{{'vidas.modal_migrar.entendi' | translate}}"
				icon="warning" title="{{'vidas.modal_migrar.migrar_plano_fail_title' | translate}}"
				text="{{'vidas.modal_migrar.migrar_plano_fail_text' | translate}}"
				(confirmCallback)="fecharSwalPlanoMigradoComSucessoOuNao()"
				(cancelCallback)="fecharSwalPlanoMigradoComSucessoOuNao()">
			</default-swal>
			<default-swal #swalPlanoMigracaoEmAndamento confirmButtonText="{{'vidas.modal_migrar.entendi' | translate}}"
				icon="warning" title="{{'vidas.modal_migrar.migrar_plano_warn_title' | translate}}"
				text="{{'vidas.modal_migrar.migrar_plano_warn_text' | translate}}"
				(confirmCallback)="fecharSwalPlanoMigradoComSucessoOuNao()"
				(cancelCallback)="fecharSwalPlanoMigradoComSucessoOuNao()">
			</default-swal>
		</div>
	</div>
</div>