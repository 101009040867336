import {CurrencyPipe} from '@angular/common'
import {Component, OnInit, ViewChild} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {ValidateBrService} from 'angular-validate-br'
import {BehaviorSubject} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {PlanoEmpresa} from 'src/app/model/interfaces/inclusao-vida-popup'
import {OptionsUpload} from 'src/app/model/interfaces/options-upload'
import {RadioOption} from 'src/app/model/interfaces/radio-option'
import {ApiService} from 'src/app/service/api.service'
import {CompromissoService} from 'src/app/service/compromisso.service'
import {LIMIT_CPF, NAO_INFORMADO} from 'src/app/utils/constants'
import {Titularidade} from 'src/app/utils/enums'
import {formatarStringParaCamelCase} from 'src/app/utils/util'

import {MigracaoPlanoPopupSteps} from './migracao-plano-steps.enum'
import {FileUploadPopup, MigracaoPlano} from './migracao-plano.interface'
import {DefaultSwalModalComponent} from 'src/app/others-component/default-swal-modal/default-swal-modal.component'
import {UploadPopupComponent} from 'src/app/others-component/upload-popup/upload-popup.component'
import {TypeFile} from 'src/app/others-component/upload-popup/type-file'

@Component({
	selector: 'app-migracao-plano-popup',
	templateUrl: './migracao-plano-popup.component.html',
	styleUrls: [
		'./migracao-plano-popup.component.scss'
		// '../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		// '../../../vendor/libs/ng-select/ng-select.scss',
		// '../../../vendor/libs/spinkit/spinkit.scss',
		// '../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss',
	],
	providers: [CurrencyPipe]
})
export class MigracaoPlanoPopupComponent implements OnInit {
	tituloModal = ''
	labelAvancar = ''
	labelVoltar = ''

	idEmpresaS4E: number
	idEmpresa: number
	idCompromisso: number
	nomeArquivo = ''

	buscandoCPF = false
	beneficiarioJaCadastrado = false
	buscandoColaboradoresTitulares = false
	desabilitarBotaoAvancar: () => {}
	beneficiarioEncontrado = true
	cpfBeneficiario: string = null

	loading = false
	uploadImagemRealizado = false
	imagemEnviada
	arquivosEnviados: FileUploadPopup[] = []

	exibirForm = false

	patternCaracteresEspeciaiseEspaco = new RegExp(/\.|\-|\(|\)|\s/g)

	listaProdutos: PlanoEmpresa[]
	opcoesPlanos: []
	opcoesPlanoRadioGroup: RadioOption[] = []
	telefoneInformado = true
	planoAtual: any

	readonly steps = [
		MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA,
		MigracaoPlanoPopupSteps.STEP_SELECAO_PRODUTO,
		MigracaoPlanoPopupSteps.STEP_ENVIO_FOTO
	]

	currentStep = new BehaviorSubject<MigracaoPlanoPopupSteps>(
		MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA
	)

	migracaoPlanoPopupStepEnum = MigracaoPlanoPopupSteps

	@ViewChild('swalPlanoMigradoComSucesso')
	swalPlanoMigradoComSucesso: DefaultSwalModalComponent

	@ViewChild('swalPlanoNaoMigrado')
	swalPlanoNaoMigrado: DefaultSwalModalComponent

	@ViewChild('swalPlanoMigracaoEmAndamento')
	swalPlanoMigracaoEmAndamento: DefaultSwalModalComponent

	formInformacoesVida = new FormGroup({
		cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
		nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
		nomeMae: new FormControl(null, [Validators.required]),
		dataNascimento: new FormControl(null, [Validators.required]),
		sexo: new FormControl(null, [Validators.required]),
		telefone: new FormControl(null, [Validators.required]),
		email: new FormControl(null, [Validators.email]),
		matricula: new FormControl(null),
		grauParentesco: new FormControl(null, [Validators.required]),
		idPlanoAtual: new FormControl(null, [Validators.required])
	})

	get getFormInformacoesVida() {
		return this.formInformacoesVida.controls
	}

	formMigrarPlano = new FormGroup({
		idPlanoAtual: new FormControl(null, [Validators.required]),
		idNovoPlano: new FormControl(null, [Validators.required])
	})

	get getFormMigrarPlano() {
		return this.formMigrarPlano.controls
	}

	constructor(
		private currencyPipe: CurrencyPipe,
		private activeModal: NgbActiveModal,
		private compromissoService: CompromissoService,
		private apiService: ApiService,
		private validateBrService: ValidateBrService,
		private ngbModal: NgbModal
	) {}

	ngOnInit() {
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())

		this.getFormInformacoesVida.cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(async _ => {
				this.resetFormInformacoesVida()

				this.exibirForm = false
				const cpfFormatado = this.getFormInformacoesVida.cpf.value.replace(
					this.patternCaracteresEspeciaiseEspaco,
					''
				)

				if (cpfFormatado.length === LIMIT_CPF) {
					await this.buscarBeneficiarioCadastradoS4E(this.idEmpresaS4E, cpfFormatado)

					this.getFormMigrarPlano.idPlanoAtual.setValue(this.planoAtual?.idPlano)

					const planosFiltrados = this.listaProdutos.filter(
						produto => produto.idPlano !== this.planoAtual?.idPlano
					)

					this.opcoesPlanoRadioGroup = planosFiltrados.map(produto => ({
						value: produto.idPlano,
						label: produto.plano.nome,
						description: `(Titular:  ${this.currencyPipe.transform(
							produto.valorTitular,
							'BRL'
						)} | Dependente: ${this.currencyPipe.transform(produto.valorDependente, 'BRL')})`
					}))
				}
			})

		if (this.cpfBeneficiario) {
			this.getFormInformacoesVida.cpf.patchValue(this.cpfBeneficiario, {emitEvent: true})
		}
	}

	produtoSelecionado(produtoSelecionado: RadioOption) {
		this.formMigrarPlano.patchValue({
			idNovoPlano: produtoSelecionado.value
		})
	}

	async buscarBeneficiarioCadastradoS4E(codEmpresaS4E: number, cpf: string) {
		this.buscandoCPF = true
		await this.compromissoService
			.listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(codEmpresaS4E, cpf)
			.then(({rows}) => {
				const controlsInformacoesVida = this.getFormInformacoesVida
				if (rows.length) {
					const vidaS4E = rows[0]
					controlsInformacoesVida.nome.setValue(vidaS4E.nomeFormatado)
					controlsInformacoesVida.nomeMae.setValue(formatarStringParaCamelCase(vidaS4E.nomeMae))
					controlsInformacoesVida.dataNascimento.setValue(vidaS4E.dataNascimento)
					controlsInformacoesVida.idPlanoAtual.setValue(vidaS4E.idPlano)
					controlsInformacoesVida.grauParentesco.setValue(vidaS4E.vinculo)
					controlsInformacoesVida.sexo.setValue(vidaS4E.sexo)
					controlsInformacoesVida.matricula.setValue(NAO_INFORMADO)

					vidaS4E.email
						? controlsInformacoesVida.email.setValue(vidaS4E.email)
						: controlsInformacoesVida.email.setValue(NAO_INFORMADO)

					vidaS4E.celular
						? controlsInformacoesVida.telefone.setValue(vidaS4E.celular)
						: controlsInformacoesVida.telefone.setValue(NAO_INFORMADO)
					this.telefoneInformado = !!vidaS4E.celular
					this.beneficiarioEncontrado = true
					this.exibirForm = true
				} else {
					this.buscandoCPF = false
					this.beneficiarioEncontrado = false
				}
			})
			.finally(() => {
				this.planoAtual = this.listaProdutos.find(
					produto => produto.idPlano === this.getFormInformacoesVida.idPlanoAtual.value
				)
			})
		this.buscandoCPF = false
		this.formInformacoesVida.disable()
		this.getFormInformacoesVida.cpf.enable()
	}

	resetFormInformacoesVida() {
		Object.keys(this.formInformacoesVida.controls).forEach(key => {
			if (key !== 'cpf') {
				this.formInformacoesVida.get(key)?.reset()
			}
		})
	}

	salvarMigracaoPlano() {
		this.loading = true
		const {valorTitular, valorDependente} = this.listaProdutos.find(
			produto => produto.idPlano === this.getFormMigrarPlano.idNovoPlano.value
		)
		const valorNovoPlano =
			this.getFormInformacoesVida.grauParentesco.value === Titularidade.Titular
				? valorTitular
				: valorDependente

		const body: MigracaoPlano = {
			valorNovoPlano,
			codigoNovoPlano: this.getFormMigrarPlano.idNovoPlano.value,
			codigoEmpresa: this.idEmpresa,
			cpfBeneficiario: this.getFormInformacoesVida.cpf.value,
			idCompromisso: this.idCompromisso
		}

		this.compromissoService
			.migrarPlano(body)
			.then(() => this.swalPlanoMigradoComSucesso.openModal())
			.catch(erro => {
				if (erro.status === 418) {
					return this.swalPlanoMigracaoEmAndamento.openModal()
				}
				return this.swalPlanoNaoMigrado.openModal()
			})
			.finally(() => {
				this.loading = false
				this.activeModal.close()
			})
	}

	openWebcam() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showWebcam: true
		}

		this.openUploadPopup(optionsUpload)
	}

	openUploadPopup(optionsUpload: OptionsUpload) {
		const options: NgbModalOptions = {windowClass: 'modal-lg animate'}

		const modal = this.ngbModal.open(UploadPopupComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)

		modal.result.then(result => {
			if (result && result.dataURL) {
				this.arquivosEnviados = [{filename: optionsUpload.fileName, size: result?.size}]
				this.imagemEnviada = result.dataURL
				this.uploadImagemRealizado = true
			}
		})
	}

	openDragdrop() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: true,
			aspectRatio: 4 / 4,
			showDragdrop: true
		}

		this.openUploadPopup(optionsUpload)
	}

	avancarStep(step?: MigracaoPlanoPopupSteps) {
		if (!step) {
			this.currentStep.next(this.currentStep.getValue() + 1)
		} else {
			this.currentStep.next(step)
		}
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	voltarStep() {
		this.currentStep.next(this.currentStep.getValue() - 1)
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	configurarLabelsEValidacaoStep(step: MigracaoPlanoPopupSteps) {
		switch (step) {
			case MigracaoPlanoPopupSteps.STEP_FORM_INFORMACOES_VIDA:
				this.tituloModal = 'Migrar de plano'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'

				this.desabilitarBotaoAvancar = () =>
					this.formInformacoesVida.invalid || !this.beneficiarioEncontrado
				break

			case MigracaoPlanoPopupSteps.STEP_SELECAO_PRODUTO:
				this.tituloModal = 'Migração de plano'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'

				this.desabilitarBotaoAvancar = () => this.formMigrarPlano.invalid
				break

			case MigracaoPlanoPopupSteps.STEP_ENVIO_FOTO:
				this.tituloModal = 'Enviar arquivos'
				this.labelAvancar = 'Salvar migração'
				this.labelVoltar = 'Voltar'

				const nomeVida = this.formInformacoesVida.get('nome')?.value
				this.nomeArquivo = `${nomeVida}`

				this.desabilitarBotaoAvancar = () =>
					!(this.imagemEnviada && this.uploadImagemRealizado) || this.loading
				break
		}
	}

	fecharSwalPlanoMigradoComSucessoOuNao() {
		this.avancarStep()
	}

	dismiss() {
		this.activeModal.dismiss()
	}
}
