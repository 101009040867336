import { PlanoEmpresa } from "src/app/model/interfaces/inclusao-vida-popup"
import { FileUploadPopup } from "../../migrar-plano-popup/migrar-plano.interface"

export class DadosInclusaoVidas {
	tipoVida: string = ''
	idEmpresa: number = 0
	idEmpresaS4e: number = 0
	nomeEmpresa: string = ''
	idCompromisso: number = 0
	codAssociadoS4e: number
	nomeTitular: string
	idTitular: number
	titularVinculado: string
	cpfTitular: string
	nomeArquivo: string = ''
	urlFicha: string
	arquivosEnviados: FileUploadPopup[]
	listaProdutos: PlanoEmpresa[]	
}

export interface TipoVidaSelect {
	value: string
	descricao: string
}

export interface TitularSelect {
	value: number
	descricao: string
	cpf: string
	idEmpresaS4e: number
	idEmpresa: number
}

export interface EmpresaSelect {
	value: number
	descricao: string
	idEmpresaS4e: number
	idEmpresa: number
}