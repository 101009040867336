<button
	*ngIf="!isMenuButton"
	[type]="type"
	[disabled]="disabled"
	(click)="click()"
	class="btn btn-anim ladda-button font-weight-semibold"
	[ladda]="loading"
	data-style="zoom-in"
	[ngClass]="{
		'btn-primary': cssClass === 'PRIMARY',
		'btn-outline-dark': cssClass === 'OUTLINE',
		'btn-dark': cssClass === 'DARK'
	}">
	{{ label }}
</button>

<div class="btn-group dropdown" ngbDropdown>
	<button
		*ngIf="isMenuButton"
		[type]="type"
		[disabled]="disabled"
		(click)="click()"
		class="btn btn-anim ladda-button font-weight-semibold dropdown-toggle"
		ngbDropdownToggle
		[ladda]="loading"
		data-style="zoom-in"
		aria-haspopup="true"
		[ngClass]="{
			'btn-primary': cssClass === 'PRIMARY',
			'btn-outline-dark': cssClass === 'OUTLINE',
			'btn-dark': cssClass === 'DARK'
		}">
		{{ label }}
	</button>

	<div
		ngbDropdownMenu
		class="dropdown-menu"
		[attr.x-placement]="placement"
		style="top: 0px; left: 0px">
		<a
			ngbDropdownItem
			*ngFor="let buttonMenuOption of menuButtonOptions"
			(click)="buttonMenuOption.onClick()"
			class="dropdown-item"
			>{{ buttonMenuOption.label }}</a
		>
	</div>
</div>
