import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'default-header-title',
  templateUrl: './default-header-title.component.html',
  styleUrls: ['./default-header-title.component.scss']
})
export class DefaultHeaderTitleComponent {

  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  @Input() tituloModal: string = ''
  dismiss() {
    this.onDismiss.emit()
  }
}
