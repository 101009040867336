<div class="d-flex justify-content-between align-items-center flex-column step-add-new">
	<svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="63.5" cy="63.5" r="61.5" stroke="#E59500" stroke-width="4" />
		<path fill-rule="evenodd" clip-rule="evenodd"
			d="M54.1263 66.4998C58.8709 66.4998 62.7305 62.6403 62.7305 57.8957C62.7305 53.1511 58.8709 49.2915 54.1263 49.2915C49.3817 49.2915 45.5221 53.1511 45.5221 57.8957C45.5221 62.6403 49.3817 66.4998 54.1263 66.4998ZM54.1263 54.2082C56.1667 54.2082 57.8138 55.8553 57.8138 57.8957C57.8138 59.9361 56.1667 61.5832 54.1263 61.5832C52.0859 61.5832 50.4388 59.9361 50.4388 57.8957C50.4388 55.8553 52.0859 54.2082 54.1263 54.2082ZM43.7276 78.7915H54.2492C54.1755 79.1848 54.1263 79.6028 54.1263 80.0207V83.7082H36.918V80.0207C36.918 74.2928 48.3738 71.4165 54.1263 71.4165C55.503 71.4165 57.1992 71.5886 58.9938 71.9082C57.2484 73.0882 55.798 74.5632 54.9621 76.3578C54.827 76.3578 54.6857 76.3516 54.5443 76.3455L54.5442 76.3455C54.4029 76.3393 54.2615 76.3332 54.1263 76.3332C50.3651 76.3332 46.1613 77.5623 43.7276 78.7915ZM72.5221 72.9998C67.9988 72.9998 59.0013 75.4828 59.0013 80.3748V84.0623H86.043V80.3748C86.043 75.4828 77.0455 72.9998 72.5221 72.9998ZM78.7096 62.8123C78.7096 65.1232 77.4067 67.1144 75.5384 68.1715C74.6534 68.6632 73.6455 68.9582 72.5638 68.9582C71.4821 68.9582 70.4742 68.6632 69.5892 68.1715C67.7209 67.1144 66.418 65.1232 66.418 62.8123C66.418 59.4198 69.1713 56.6665 72.5638 56.6665C75.9563 56.6665 78.7096 59.4198 78.7096 62.8123Z"
			fill="#E59500" />
		<path
			d="M82.0872 45.9161V39.4585H77.7852V45.9161H71.332C71.3535 45.9376 71.332 50.2212 71.332 50.2212H77.7852V56.6573C77.8067 56.6788 82.0872 56.6573 82.0872 56.6573V50.2212H88.5404V45.9161H82.0872Z"
			fill="#E59500" />
	</svg>

	<div class="text-align-center mt-4">
		<span class="inclusao-dependente">{{ 'vidas.modal_vida_avulsa.is_add_dependente' | translate }}<br /><span
				class="d-flex justify-content-center inclusao-dependente-titular-nome">{{ nomeTitular }}?
			</span>
		</span>
	</div>
</div>