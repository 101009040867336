<nav class="footer" [ngClass]="currentBg()">
	<div
		class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
		<div class="pt-3">
			<span class="footer-text font-weight-bolder">{{ 'menu.agisales' | translate }}</span> ©
			<span> v{{ appVersion }} </span>
		</div>
		<div>
			<a href="https://www.agiben.com.br" target="_blank" class="footer-link pt-3">Sobre nós</a>
			<!-- <a href="https://help.dant.com.br" target="_blank" class="footer-link pt-3 ml-4">Ajuda</a> -->
			<a href="javascript:void(0)" *ngIf="false" class="footer-link pt-3 ml-4"
				>Terms &amp; Conditions</a
			>
		</div>
	</div>
</nav>
