import {Component, Input, OnInit} from '@angular/core'
import {TranslateService} from '@ngx-translate/core'

export enum BadgeType {
	SUCCESS = 'SUCCESS',
	SALESFARM = 'SALES FARM',
	FIELDSALES = 'FIELD SALES',
	SALESREP = 'SALES REP',
	ACCOUNT = 'ACCOUNT',
	LABORAL = 'LABORAL',
	PATRONAL = 'PATRONAL',
	COMISSOES = 'COMISSOES',
	DANGER = 'DANGER',
	INACTIVE = 'INACTIVE',
}

@Component({
	selector: 'badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
	@Input() perfil: string = null
	@Input() valor: string = null

	badgeType = BadgeType

	validsProfiles = [
		'SALES REP',
		'SALES FARM',
		'FIELD SALES',
		'ACCOUNT',
		'SUCCESS',
		'DANGER',
		'INACTIVE',
	]

	isDefault = false

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.isDefault = !this.validsProfiles.find(item => item === this.perfil)
	}
}
