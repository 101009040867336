import { PlatformLocation } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
// https://www.npmjs.com/package/@ngx-pwa/local-storage
import { LocalStorage } from '@ngx-pwa/local-storage'

// https://stackblitz.com/edit/ng-brazil?file=src%2Fapp%2Fapp.component.html
import * as textMaskAddons from 'text-mask-addons/dist/textMaskAddons'
import { Login } from '../model/login'

import { AtualizarCompromissoCalendarioDTO } from 'src/app/model/compromissos/atualizar-compromisso-calendario-dto'
import { environment } from '../../environments/environment'
import { Lives, LivesKpis } from '../cadastros/sales-empresa-edit/interfaces'
import { MigracaoPlano } from '../mfe/popup/migracao-plano-popup/migracao-plano.interface'
import { AtividadeCalendarioDTO } from '../model/atividades/atividade-calendario-dto.model'
import { InclusaoVidaNestDTO } from '../model/cliente-compromisso/inclusao-vida-nest.dto'
import { SincronizarVidasDTO } from '../model/cliente-compromisso/sincronizar-vidas.dto'
import { CancelarCompromissoDTO } from '../model/compromissos/cancelar-compromisso-dto'
import { CompromissoPorIdDTO } from '../model/compromissos/compromisso-por-id.dto'
import { CriarCompromissoCalendarioDTO } from '../model/compromissos/criar-compromisso-calendario-dto'
import { PlanoEmpresa } from '../model/interfaces/inclusao-vida-popup'
import { EmpresaEColaboradorFilterDTO } from '../model/vidas/vidas-filtro-dto'
import { CompromissoStatusEnum } from '../utils/enums'
import { converterDataEHoraParaTimezoneAmericaRecife } from '../utils/util'
import { AuthService } from './auth/auth.service'


@Injectable({
	providedIn: 'root',
})
export class CompromissoService {
	hostName: any
	hostPort: any
	urlServerAPI: any
	urlServerAPINest: any
	verdescricaocliente: boolean
	login: Login
	headers: any
	headersNest: any
	isRemoteServer = false
	isDev = false
	tipo: any

	dateMaskOptions = {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: textMaskAddons.createAutoCorrectedDatePipe('dd/mm/yyyy'),
	}

	constructor(
		private platformLocation: PlatformLocation,
		private http: HttpClient,
		private authService: AuthService,
		protected localStorage: LocalStorage,
	) {
		// https://stackoverflow.com/questions/49297680/angular-5-get-host-name-and-app-name-from-url
		// Example: localhost:4200
		this.hostName = (platformLocation as any).location.origin
		// Example: 4200
		this.hostPort = (platformLocation as any).location.port

		this.urlServerAPI = environment.API_URL
		this.urlServerAPINest = environment.API_URL_NEST
	}

	//#region Compromissos

	async getCompromissoPorIDContaMesAno(
		idConta: number,
		mes: number,
		ano: number,
	): Promise<AtividadeCalendarioDTO[]> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				'compromissos/' +
				idConta +
				'/' +
				mes +
				'/' +
				ano +
				'/busca_por_id_usuario_mes_ano'

			retorno = await this.http.get<AtividadeCalendarioDTO[]>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getCompromissoPorIDContaMesAno')
		}

		return retorno
	}

	async createCompromissoCalendarioNest(params: CriarCompromissoCalendarioDTO): Promise<any> {
		let retorno = null
		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/calendario'

			retorno = await this.http.post<any>(urlAPI, params).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'createCompromissosCalendarioNest')
		}

		return retorno
	}

	async updateCompromissoCalendarioNestPorID(
		params: AtualizarCompromissoCalendarioDTO,
		id: number,
	): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/calendario/' + id

			retorno = await this.http.patch<any>(urlAPI, params).toPromise()

			const data = retorno[0]
		} catch (error) {
			console.log(error)
			this.authService.handleError(error, 'updateCompromissoCalendarioNestPorID')
		}

		return retorno
	}

	async listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(idEmpresaS4E: number, filtro: any) {
		const urlAPI =
			this.urlServerAPINest + `cliente_compromissos/cliente/${filtro}/empresa/${idEmpresaS4E}`

		try {
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E')
		}
	}

	async listarBeneficiarioPorNomeOuCpf(filtro: EmpresaEColaboradorFilterDTO) {
		const urlAPI = this.urlServerAPINest + `vidas/colaboradores`
		try {
			return await this.http.post<any>(urlAPI, filtro).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarBeneficiarioPorNomeOuCpf')
		}
	}

	async listarEmpresasPorNomeCnpjOuCodigo(filtro: EmpresaEColaboradorFilterDTO) {
		const urlAPI = this.urlServerAPINest + `vidas/empresas`
		try {
			return await this.http.post<any>(urlAPI, filtro).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarEmpresasPorNomeCnpjOuCodigo')
		}
	}

	async listarBeneficiarioAtivoPorCpf(cpf: string) {
		const urlAPI = this.urlServerAPINest + `cliente_compromissos/cliente/${cpf}/empresa`
		try {
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarBeneficiarioAtivoPorCpf')
		}
	}

	async inserirVidaNestNovo(body: InclusaoVidaNestDTO): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'clientes/criarcomclientecompromisso'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			console.log('erro criar vida: ', error)
			this.authService.handleError(error, 'inserirVidaNestNovo')
			retorno = error
		}

		return retorno
	}

	async getCompromissoNestPorID(id: number): Promise<CompromissoPorIdDTO> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			console.log(error)
			this.authService.handleError(error, 'getCompromissoNestPorID')
		}

		return retorno
	}

	async sincronizarVidasTitularDependentePorIdEmpresa(
		idEmpresa: number,
	): Promise<SincronizarVidasDTO> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				`cliente_compromissos/sincronizar-vidas-titular-dependente-s4e/${idEmpresa}`

			retorno = await this.http.post<any>(urlAPI, {}).toPromise()
		} catch (error) {
			console.log(error)
			this.authService.handleError(error, 'sincronizarVidasTitularDependentePorIdEmpresa')
		}

		return retorno
	}

	async listarVidasPorEmpresa(idEmpresa: number, body = {}) {
		return await this.http
			.post<Lives>(
				`${this.urlServerAPINest}cliente_compromissos/listar-vidas-empresa/${idEmpresa}`,
				body,
			)
			.toPromise()
	}

	async calcularKpisVidasPorEmpresaUsuario(idEmpresa: number) {
		return await this.http
			.get<LivesKpis>(
				`${this.urlServerAPINest}cliente_compromissos/calcular-kpis-vidas-empresa-usuario/${idEmpresa}`,
			)
			.toPromise()
	}

	async listarCompromissoVidaAvulsa(idEmpresa: number) {
		return await this.http
			.get<CompromissoPorIdDTO>(
				`${this.urlServerAPINest}compromissos/listar-compromisso-vida-avulsa/${idEmpresa}`,
			)
			.toPromise()
	}

	async buscarPlanosPorIdEmpresaS4e(idEmpresaS4e: number): Promise<PlanoEmpresa[]> {
		try {
			return await this.http
				.get<PlanoEmpresa[]>(`${this.urlServerAPINest}empresas/${idEmpresaS4e}/plano`)
				.toPromise()
		} catch (error) {
			console.log(error)
			this.authService.handleError(error, 'buscarPlanosPorIdEmpresaS4e')
		}
	}

	async migrarPlano(body: MigracaoPlano) {
		return await this.http
			.post(`${this.urlServerAPINest}cliente_compromissos/migrar-plano`, body)
			.toPromise()
	}

	async novoSincronizarVidasTitularDependentePorIdEmpresa(): Promise<SincronizarVidasDTO> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				`cliente_compromissos/novo-sincronizar-vidas-titular-dependente-s4e`

			retorno = await this.http.post<any>(urlAPI, {}).toPromise()
		} catch (error) {
			console.log(error)
			this.authService.handleError(error, 'sincronizarVidasTitularDependentePorIdEmpresa')
		}

		return retorno
	}

	async cancelarCompromisso(body: CancelarCompromissoDTO, idCompromisso: number) {
		return await this.http
			.post(`${this.urlServerAPINest}compromissos/cancelar-compromisso/${idCompromisso}`, body)
			.toPromise()
	}

	listarOpcoesStatusCriacao() {
		return [
			{
				description: 'Planejada',
				value: '1',
			},
			{
				description: 'Trabalhando',
				value: '2',
			},
		]
	}

	listarOpcoesStatusEdicao() {
		return [
			{
				description: 'Planejada',
				value: '1',
			},
			{
				description: 'Trabalhando',
				value: '2',
			},
			{
				description: 'Finalizada',
				value: '4',
			},
			{
				description: 'Cancelado',
				value: '5',
			},
		]
	}
	//#endregion

	async createCompromissoNest(params: any): Promise<any> {
		let retorno = null

		try {
			const body = {
				fk_idempresa: params.empresa,
				fk_idconta: params.atribuirA,
				com_inicio: converterDataEHoraParaTimezoneAmericaRecife(params.data, params.horaInicio),
				com_fim: converterDataEHoraParaTimezoneAmericaRecife(params.data, params.horaTermino),
				com_statusreuniao: CompromissoStatusEnum.Planejada,
				com_tipo: params.tipo,
				com_obs: params.observacoes,
			}

			const urlAPI = this.urlServerAPINest + 'compromissos'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'createCompromissoNest')
		}

		return retorno
	}

	async atualizarCompromissoNest(params: any): Promise<any> {
		let retorno = null

		try {
			const body = {
				pk_id_compromisso: params.idCompromisso,
				fk_idempresa: params.empresa,
				fk_idconta: params.atribuirA,
				com_inicio: converterDataEHoraParaTimezoneAmericaRecife(params.data, params.horaInicio),
				com_fim: converterDataEHoraParaTimezoneAmericaRecife(params.data, params.horaTermino),
				com_statusreuniao: CompromissoStatusEnum.Planejada,
				com_tipo: params.tipo,
				com_obs: params.observacoes,
			}

			const urlAPI = this.urlServerAPINest + `compromissos/${params.idCompromisso}`

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'createCompromissoNest')
		}

		return retorno
	}
}
