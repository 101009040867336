<sidenav [orientation]="orientation" [ngClass]="getClasses()">
	<!-- Brand demo (see src/demo.css) -->
	<div class="app-brand demo" *ngIf="orientation !== 'horizontal'">
		<span class="app-brand-logo demo">
			<!-- <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt"
        viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">



        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M953 1993 c26 -2 68 -2 95 0 26 2 4 3 -48 3 -52 0 -74 -1 -47 -3z" />
          <path d="M757 1969 c-43 -10 -86 -24 -95 -30 -15 -9 -14 -10 3 -4 32 10 177
      43 210 48 28 4 28 4 -5 5 -19 0 -70 -8 -113 -19z" />
          <path d="M1125 1983 c33 -5 178 -38 210 -48 17 -6 18 -5 4 4 -24 15 -179 51
      -214 49 l-30 -1 30 -4z" />
          <path d="M600 1915 c-14 -8 -20 -14 -15 -14 6 0 21 6 35 14 14 8 21 14 15 14
      -5 0 -21 -6 -35 -14z" />
          <path d="M1380 1915 c14 -8 30 -14 35 -14 6 0 -1 6 -15 14 -14 8 -29 14 -35
      14 -5 0 1 -6 15 -14z" />
          <path d="M545 1890 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
          <path d="M1430 1896 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
          <path d="M445 1830 c-120 -79 -256 -225 -315 -340 -20 -38 -7 -22 50 64 67
      100 166 199 266 266 84 55 90 60 82 60 -3 0 -40 -23 -83 -50z" />
          <path d="M1470 1877 c3 -3 40 -29 84 -57 100 -67 199 -166 266 -266 57 -86 70
      -102 50 -64 -71 137 -243 309 -380 380 -14 7 -23 10 -20 7z" />
          <path d="M657 1528 c-8 -13 -80 -139 -160 -280 l-147 -256 84 -149 c46 -81
      117 -206 159 -278 l75 -130 156 -3 c86 -1 156 1 156 6 0 5 -70 131 -155 281
      l-156 272 150 262 c82 144 153 270 156 280 7 16 -4 17 -149 17 -151 0 -156 -1
      -169 -22z" />
          <path d="M1034 1535 c3 -9 73 -134 156 -279 l150 -264 -150 -266 c-83 -146
      -153 -272 -156 -281 -5 -13 13 -15 150 -15 151 0 156 1 169 23 8 12 80 138
      161 280 l146 257 -158 278 -157 277 -159 3 c-140 2 -158 1 -152 -13z" />
          <path d="M105 1449 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
          <path d="M1886 1447 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
          <path d="M82 1395 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
      -21z" />
          <path d="M1901 1415 c0 -5 6 -21 14 -35 8 -14 14 -20 14 -15 0 6 -6 21 -14 35
      -8 14 -14 21 -14 15z" />
          <path d="M51 1318 c-20 -61 -41 -163 -40 -193 0 -16 7 4 15 45 7 41 20 97 28
      125 17 55 14 76 -3 23z" />
          <path d="M1935 1335 c14 -44 45 -182 46 -210 2 -26 2 -27 6 -6 5 27 -32 198
      -48 221 -9 12 -10 11 -4 -5z" />
          <path d="M4 1000 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z" />
          <path d="M1994 1000 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z" />
          <path d="M12 875 c-2 -35 34 -190 49 -214 9 -14 10 -13 4 4 -10 32 -43 177
      -48 210 l-4 30 -1 -30z" />
          <path d="M1981 875 c-1 -28 -32 -166 -46 -210 -6 -16 -5 -17 4 -5 16 23 53
      194 48 221 -4 21 -4 20 -6 -6z" />
          <path d="M71 635 c0 -5 6 -21 14 -35 8 -14 14 -20 14 -15 0 6 -6 21 -14 35 -8
      14 -14 21 -14 15z" />
          <path d="M1912 615 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
      -21z" />
          <path d="M100 561 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
          <path d="M1886 553 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
          <path d="M130 510 c71 -137 243 -309 380 -380 38 -20 22 -7 -64 50 -100 67
      -199 166 -266 266 -57 86 -70 102 -50 64z" />
          <path d="M1821 449 c-67 -101 -166 -201 -267 -269 -86 -57 -102 -70 -64 -50
      136 70 306 240 379 378 22 42 5 22 -48 -59z" />
          <path d="M547 109 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
          <path d="M1440 110 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
          <path d="M600 85 c14 -8 30 -14 35 -14 6 0 -1 6 -15 14 -14 8 -29 14 -35 14
      -5 0 1 -6 15 -14z" />
          <path d="M1380 85 c-14 -8 -20 -14 -15 -14 6 0 21 6 35 14 14 8 21 14 15 14
      -5 0 -21 -6 -35 -14z" />
          <path d="M660 61 c23 -16 194 -53 221 -48 21 4 20 4 -6 6 -28 1 -166 32 -210
      46 -16 6 -17 5 -5 -4z" />
          <path d="M1290 54 c-25 -8 -79 -21 -120 -28 -41 -8 -61 -15 -45 -15 34 -1 189
      34 214 49 22 13 5 11 -49 -6z" />
          <path d="M953 3 c26 -2 68 -2 95 0 26 2 4 3 -48 3 -52 0 -74 -1 -47 -3z" />
        </g>
      </svg> -->

			<svg data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="100 100 700 900">
				<path
					d="M493.74 524.26h-15.11a13.6 13.6 0 0 1-10.84-5.38l-16.35-21.51q-33.35 19.36-40.32 22a112.67 112.67 0 0 1-45.71 9.68q-39.8 0-64-24.74-25.81-25.82-25.81-69.9 0-38.18 29.57-60.49t78-22.31a261 261 0 0 1 59.14 7v-6.46q0-61.29-47.31-61.29-16.07 0-68.27 13.22a13.62 13.62 0 0 1-15.89-7.89l-8.49-20.06a13.61 13.61 0 0 1 9.51-18.58Q375.33 243 408.43 243q53.24 0 73.66 30.65 16.14 24.19 16.13 78v115.07l8.83 41.06a13.62 13.62 0 0 1-13.31 16.48Zm-51.44-72.94v-39.08A13.57 13.57 0 0 0 431.77 399a163.68 163.68 0 0 0-36.25-4.31q-30.64 0-47.58 11.29T331 436.07q0 18.84 16.13 30.38T387.46 478q31.47 0 50.25-16.49a13.56 13.56 0 0 0 4.59-10.19Zm300.87-60.41h-15.12a13.61 13.61 0 0 0-10.83 5.37l-16.35 21.51q-33.35-19.35-40.33-22a112.42 112.42 0 0 0-45.7-9.68q-39.8 0-64 24.73-25.8 25.82-25.81 69.9 0 38.18 29.58 60.49t78 22.32a261.63 261.63 0 0 0 59.15-7V563q0 61.31-47.32 61.3-16.06 0-68.27-13.22a13.61 13.61 0 0 0-15.88 7.88L551.76 639a13.62 13.62 0 0 0 9.51 18.58q63.48 14.55 96.58 14.55 53.24 0 73.67-30.65 16.13-24.19 16.13-78V448.44l8.83-41.06a13.61 13.61 0 0 0-13.31-16.47Zm-51.44 72.93v39.09a13.58 13.58 0 0 1-10.53 13.26 164.31 164.31 0 0 1-36.25 4.3q-30.65 0-47.59-11.29t-16.93-30.11q0-18.82 16.13-30.38t40.32-11.56q31.47 0 50.26 16.49a13.56 13.56 0 0 1 4.59 10.2ZM293.81 702.72a15.86 15.86 0 0 1 20.89.6q73.35 67.92 199.81 67.91 67.61 0 114.55-15a241.28 241.28 0 0 0 89.54-51.29 15.83 15.83 0 0 1 20-.92l11.77 8.73a15.85 15.85 0 0 1 2.09 23.61q-47.52 50.28-119.64 77.46Q570.84 837 514.51 837q-58.83 0-122.06-25.67-66-27.15-112.32-75a15.87 15.87 0 0 1 1.22-23.3Z"
					style="fill: #ffffff" />
				<rect
					x="751"
					y="656.01"
					width="47.94"
					height="49.37"
					rx="12.02"
					transform="rotate(-49.09 774.955 680.695)"
					style="fill: #ffffff" />
			</svg>
		</span>
		<a class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{
			'menu.agisales' | translate
		}}</a>
		<a
			href="javascript:void(0)"
			class="layout-sidenav-toggle sidenav-link text-large ml-auto"
			(click)="toggleSidenav()">
			<i class="ion ion-md-menu align-middle"></i>
		</a>
	</div>

	<div class="sidenav-divider mt-0" *ngIf="orientation !== 'horizontal'"></div>
	<!-- <div         class="badge badge-primary ml-2">Novo</div> -->

	<!-- Links -->
	<div id="sidenav" class="sidenav-inner" [ngClass]="{'py-1': orientation !== 'horizontal'}">
		<!-- <sidenav-header class="small font-weight-semibold">{{ user ? user.nomeCliente : '' }}</sidenav-header> -->

		<sidenav-menu
			icon="ion ion-logo-usd"
			text="Minhas Vendas"
			*ngIf="hasDic(Dicionario.PAINEL_DE_VENDAS)"
			[active]="isMenuActive('/dashboards')"
			[open]="isMenuOpen('/dashboards')">
			<sidenav-router-link
				route="/dashboards/dashboard-2"
				*ngIf="hasDic(Dicionario.PAINEL_DE_VENDAS)"
				[active]="isActive2('/dashboards/dashboard-2')"
				>Campanhas disponíveis
				<!-- <div class="badge badge-primary ml-2">Novo</div> -->
			</sidenav-router-link>

			<sidenav-router-link
				(click)="openVisualizarCampanhaPopup()"
				*ngIf="hasDic(Dicionario.PAINEL_DE_VENDAS)"
				>Vender agora
				<!-- <div class="badge badge-primary ml-2">Novo</div> -->
			</sidenav-router-link>
		</sidenav-menu>

		<!-- Dashboards -->
		<sidenav-menu
			icon="ion ion-md-speedometer"
			text="Gestão"
			[active]="isMenuActive('/dashboards')"
			[open]="isMenuOpen('/dashboards')">
			<sidenav-router-link
				class="ngb-popover-dark"
				route="/dashboards/dashboard-1"
				[active]="isActive2('/dashboards/dashboard-1')"
				#inicial="ngbPopover"
				placement="right"
				[ngbPopover]="popoverInicial"
				popoverTitle="Dashboard"
				data-container="body"
				[autoClose]="false"
				triggers="manual">
				Meu painel
			</sidenav-router-link>

			<!-- <sidenav-router-link route="/dashboards/avisos" [active]="isActive2('/dashboards/avisos')">Avisos
        <a class="nav-link hide-arrow">
          <i class="ion ion-ios-mail navbar-icon align-middle"></i>
          <span class="badge badge-primary badge-dot indicator"></span>
          <span class="d-lg-none align-middle">&nbsp; Mensagem</span>
        </a>
      </sidenav-router-link> -->
		</sidenav-menu>

		<sidenav-divider class="mb-1"></sidenav-divider>

		<sidenav-header class="small font-weight-semibold"
			>{{ 'menu.consultas' | translate }}
		</sidenav-header>

		<sidenav-router-link
			route="/calendario-geral"
			icon="fas fa-calendar-alt"
			*ngIf="hasDic(Dicionario.CALENDARIO)"
			[active]="isActive2('/calendario-geral')">
			{{ 'menu.calendario' | translate }}
		</sidenav-router-link>

		<sidenav-router-link
			route="/leads/list"
			icon="fas fa-bullseye"
			*ngIf="hasDic(Dicionario.LEAD)"
			[active]="isActive2('/leads')">
			{{ 'menu.leads' | translate }}
		</sidenav-router-link>

		<sidenav-router-link
			route="/assinaturas/list"
			icon="far fa-list-alt"
			*ngIf="hasDic(Dicionario.ASSINATURAS)"
			[active]="isActive2('/assinaturas')">
			{{ 'menu.assinaturas' | translate }}
		</sidenav-router-link>

		<sidenav-router-link
			route="/autorizacoes/list"
			icon="far fa-check-square"
			*ngIf="hasDic(Dicionario.SALES_AUTORIZACOES)"
			[active]="isActive2('/autorizacoes')">
			{{ 'menu.autorizacoes' | translate }}
		</sidenav-router-link>

		<!-- TODO: Reincluir quando existir a tela de pesquisa geral -->
		<!-- <sidenav-router-link [hidden]="true" route="/pesquisa-geral" icon="fas fa-search" *ngIf="hasDic(Dicionario.SALES_PESQUISA_GERAL)"
      [active]="isActive2('/pesquisa-geral')">
      {{ "menu.pesquisa_geral" | translate }}
    </sidenav-router-link> -->
		<sidenav-router-link
			route="/atividades/list"
			icon="fas fa-clipboard-list"
			*ngIf="hasDic(Dicionario.ATIVIDADE)"
			[active]="isActive2('/atividades')">
			{{ 'menu.atividades' | translate }}
		</sidenav-router-link>

		<!-- TODO: Reincluir quando existir a tela de gestão de carteira -->
		<sidenav-router-link
			[hidden]="true"
			route="/gestao-de-carteira"
			icon="fas fa-briefcase"
			*ngIf="hasDic(Dicionario.SALES_GESTAO_DE_CARTEIRAS)"
			[active]="isActive2('/gestao-de-carteira')">
			{{ 'menu.gestao_carteira' | translate }}
		</sidenav-router-link>

		<sidenav-router-link
			route="/despesas"
			icon="fas fa-dollar-sign"
			*ngIf="hasDic(Dicionario.SALES_DESPESAS)"
			[active]="isActive2('/despesas')">
			{{ 'menu.despesas' | translate }}
		</sidenav-router-link>

		<!-- Busca rápida -->

		<!-- <sidenav-router-link
			icon="ion ion-ios-calendar"
			route="/search/calendario/list"
			[active]="isActive('/search/calendario/list')"
			*ngIf="hasDic(Dicionario.CALENDARIO)">
			Calendário</sidenav-router-link
		> -->

		<sidenav-router-link
			route="/vidas/list"
			icon="far fa-list-alt"
			*ngIf="hasDic(Dicionario.VIDAS)"
			[active]="isActive2('/vidas')">
			{{ 'menu.vidas' | translate }}
		</sidenav-router-link>

		<sidenav-router-link
			icon="ion ion-md-business"
			route="/cadastros/gestao-empresas/list"
			[active]="isActive('/cadastros/gestao-empresas/list')"
			*ngIf="hasDic(Dicionario.GESTAO_EMPRESAS)">
			Gestão das empresas</sidenav-router-link
		>

		<sidenav-router-link
			icon="ion ion-ios-people"
			route="/search/beneficiario/list"
			[active]="isActive('/search/beneficiario/list')"
			*ngIf="hasDic(Dicionario.BUSCA_DE_BENEFICIARIO)">
			Por beneficiário
			<div class="badge badge-outline-success ml-2">S4E</div>
		</sidenav-router-link>

		<sidenav-router-link
			icon="ion ion-ios-people"
			route="/search/corretor/list"
			[active]="isActive('/search/corretor/list')"
			*ngIf="hasDic(Dicionario.BUSCA_POR_CORRETOR)">
			Corretor
			<div class="badge badge-outline-success ml-2">S4E</div>
		</sidenav-router-link>
		<sidenav-router-link
			icon="ion ion-ios-people"
			route="/search/clientepj/list"
			[active]="isActive('/search/clientepj/list')"
			*ngIf="hasDic(Dicionario.BUSCA_POR_CLIENTE_PJ)">
			Por cliente PJ
			<div class="badge badge-outline-success ml-2">S4E</div>
		</sidenav-router-link>

		<sidenav-router-link
			icon="ion ion-ios-people"
			route="/search/clientepf/list"
			[active]="isActive('/search/clientepf/list')"
			*ngIf="hasDic(Dicionario.CLIENTE)">
			Por cliente PF</sidenav-router-link
		>

		<sidenav-router-link
			icon="ion ion-ios-list"
			route="/search/central_relatorio/list"
			[active]="isActive('/search/central_relatorio/list')"
			*ngIf="hasDic(Dicionario.MEUS_RELATORIOS)"
			#step2="ngbPopover"
			placement="right"
			id="meusRelatorios"
			[ngbPopover]="popoverStep2"
			popoverTitle="Meus relatórios"
			data-container="body"
			[autoClose]="false"
			triggers="manual">
			Meus relatórios</sidenav-router-link
		>

		<sidenav-router-link
			icon="fas fa-calendar"
			route="/gestao-atividade/list"
			[active]="isActive('/gestao-atividade/list')"
			*ngIf="hasDic(Dicionario.GESTAO_AGENDA)">
			Gestão das agendas</sidenav-router-link
		>

		<sidenav-router-link
			icon="ion ion-ios-document"
			route="/search/segundaViaBoleto/list"
			[active]="isActive('/search/segundaViaBoleto/list')"
			*ngIf="hasDic(Dicionario.SEGUNDA_VIA_DE_BOLETO)">
			Segunda Via de Boleto</sidenav-router-link
		>

		<sidenav-router-link
			icon="ion ion-ios-search"
			route="/search/redecredenciada/list"
			[active]="isActive('/search/redecredenciada/list')"
			*ngIf="hasDic(Dicionario.REDE_CREDENCIADA)">
			Rede credenciada</sidenav-router-link
		>

		<sidenav-router-link
			icon="ion ion-ios-cart"
			route="/search/venda/list"
			[active]="isActive('/search/venda/list')"
			*ngIf="hasDic(Dicionario.VENDA)">
			Vendas</sidenav-router-link
		>

		<sidenav-menu
			*ngIf="isShowEmpresaSindicato()"
			icon="fas fa-file-alt"
			text="CCT's e ACT's"
			[active]="isMenuActive('/cadastros/cct')"
			[open]="isMenuOpen('/cadastros/cct')">
			<sidenav-router-link
				route="/cadastros/cct/list"
				[active]="isMenuActive('/cadastros/cct/list')"
				*ngIf="hasDic(Dicionario.CCT)">
				Cadastro CCT/ACT</sidenav-router-link
			>

			<sidenav-router-link
				route="/cadastros/cct/parametrizacao-list"
				[active]="isMenuActive('/cadastros/cct/parametrizacao-list')"
				*ngIf="hasDic(Dicionario.CCT_PARAMETRIZACAO)"
				>Parametrização CCT/ACT</sidenav-router-link
			>
		</sidenav-menu>

		<!-- <sidenav-router-link *ngIf="hasDic(Dicionario.QUADRO_AVISO)" route="/config/mensagem/quadro"
        [active]="isActive2('/config/mensagem/quadro')">Quadro de Avisos</sidenav-router-link> -->

		<sidenav-divider *ngIf="isShowPessoas()" class="mb-1"></sidenav-divider>
		<sidenav-header *ngIf="isShowPessoas()" class="small font-weight-semibold"
			>{{ 'menu.cadastros' | translate }}
		</sidenav-header>

		<sidenav-menu
			*ngIf="isShowEmpresaSindicato()"
			icon="fas fa-university"
			text="Sindicato"
			[active]="isMenuActive('/cadastros/sindicato')"
			[open]="isMenuOpen('/cadastros/sindicato')">
			<sidenav-router-link
				route="/cadastros/sindicato/list"
				[active]="isMenuActive('/cadastros/sindicato/list')"
				*ngIf="hasDic(Dicionario.SINDICATO)">
				Cadastro
			</sidenav-router-link>

			<sidenav-router-link
				route="/cadastros/sindicato-laboral/list"
				[active]="isMenuActive('/cadastros/sindicato-laboral/list')"
				*ngIf="hasDic(Dicionario.SINDICATO)">
				Cadastro Antigo
			</sidenav-router-link>

			<sidenav-router-link
				route="/cadastros/sindicato/gerador-link-sindicato"
				[active]="isMenuActive('/cadastros/sindicato/gerador-link-sindicato')"
				*ngIf="hasDic(Dicionario.SINDICATO)">
				Link Empresa
			</sidenav-router-link>

			<!-- <sidenav-menu *ngIf="isShowEmpresaSindicato()" text="Cadastrar Novo">




      </sidenav-menu> -->
		</sidenav-menu>

		<sidenav-menu
			*ngIf="isShowPessoas()"
			icon="ion ion-md-people"
			text="{{ 'menu.pessoas' | translate }}"
			[active]="isMenuActive('/people')"
			[open]="isMenuOpen('/people')">
			<sidenav-router-link
				route="/people/user/list"
				[active]="isActive2('/people/user')"
				*ngIf="hasDic(Dicionario.CONTA_DE_USUARIO)">
				{{ 'menu.conta' | translate }}
			</sidenav-router-link>

			<sidenav-router-link
				route="/people/corretor/edit"
				[active]="isActive2('/people/corretor')"
				*ngIf="hasDic(Dicionario.CADASTRO_VENDEDOR)">
				Cadastro de Corretor
			</sidenav-router-link>

			<!-- <sidenav-router-link route="/people/customer/list" [active]="isActive2('/people/customer')"
        *ngIf="hasDic(Dicionario.CLIENTE)">{{ 'menu.cliente' | translate }}
      </sidenav-router-link> -->
		</sidenav-menu>

		<!-- <sidenav-router-link route="/marketing/campanha/list" icon="fas fa-bullhorn" *ngIf="isShowCampanha()" -->
		<sidenav-router-link
			route="/marketing/campanha/list"
			icon="fas fa-bullhorn"
			*ngIf="hasDic(Dicionario.CAMPANHA)"
			[active]="isActive2('/marketing/campanha')">
			{{ 'menu.campanha' | translate }}
		</sidenav-router-link>

		<!-- <sidenav-menu *ngIf="isShowPessoas()" icon="ion ion-md-people" text="{{ 'menu.pessoas' | translate }}"
      [active]="isMenuActive('/people')" [open]="isMenuOpen('/people')">
      <sidenav-router-link route="/cadastros/minhas-empresas-sales" icon="ion ion-md-business"
        *ngIf="isShowMinhasEmpresasSales()" [active]="isActive2('/cadastros/minhas-empresas-sales')">
        {{ "sales.minhas_empresas" | translate }}
      </sidenav-router-link>
    </sidenav-menu> -->

		<!-- <sidenav-router-link route="/cadastros/sales-empresa/list" icon="ion ion-md-business"
    *ngIf="isShowMinhasEmpresasSales()" [active]="isActive2('/cadastros/minhas-empresas-sales')"> -->
		<sidenav-router-link
			route="/cadastros/sales-empresa/list"
			icon="ion ion-md-business"
			*ngIf="hasDic(Dicionario.SALES_EMPRESA)"
			[active]="isActive2('/cadastros/minhas-empresas-sales')">
			{{ 'sales.minhas_empresas' | translate }}
		</sidenav-router-link>

		<!-- <sidenav-router-link route="/cadastros/minhas-adesoes-sales" icon="ion ion-md-person"
  *ngIf="isShowMinhasAdesoesSales()" [active]="isActive2('/cadastros/minhas-adesoes-sales')"> -->
		<!-- <sidenav-router-link route="/cadastros/minhas-adesoes-sales" icon="ion ion-md-person"
  *ngIf="hasDic(Dicionario.SALES_ADESAO)" [active]="isActive2('/cadastros/minhas-adesoes-sales')">
      {{ "sales.minhas_adesoes" | translate }}
    </sidenav-router-link> -->

		<!-- <sidenav-router-link route="/people/empresa/edit" icon="fas fa-industry" *ngIf="isShowEmpresaSindicato()" [active]="isActive2('/people/empresa')">
      Empresa Sindicato
    </sidenav-router-link> -->

		<!-- <sidenav-router-link route="/cadastros/empresa-sindicato/edit" icon="fas fa-industry" *ngIf="isShowCampanha()"
      [active]="isActive2('/cadastros/empresa-sindicato/edit')">
      Empresa Sindicato
    </sidenav-router-link> -->

		<!-- <sidenav-router-link route="/login/authentication/gerador-link-sindicato" icon="fas fa-industry"
      *ngIf="isShowCampanha()">
      Empresa Sindicato
    </sidenav-router-link> -->

		<!-- <sidenav-divider class="mb-1"></sidenav-divider>
		<sidenav-header class="small font-weight-semibold">{{
			'menu.centro_ajuda' | translate
		}}</sidenav-header> -->

		<!-- <sidenav-router-link route="/help" icon="fas fa-video" [active]="isActive2('/help')">
			<div>Treinamento</div>
		</sidenav-router-link> -->

		<sidenav-divider class="mb-1" *ngIf="false"></sidenav-divider>
		<sidenav-header class="small font-weight-semibold" *ngIf="false">
			{{ 'menu.marketing' | translate }}</sidenav-header
		>

		<!-- <sidenav-divider *ngIf="isShowRelatorios()" class="mb-1"></sidenav-divider> -->

		<!-- Reports -->
		<sidenav-menu
			icon="ion ion-md-document"
			text="{{ 'menu.relatorios' | translate }}"
			[active]="isMenuActive('/reports')"
			[open]="isMenuOpen('/reports')"
			*ngIf="false">
			<sidenav-menu
				text="{{ 'menu.acessos' | translate }}"
				[active]="isMenuActive('/reports/access')"
				[open]="isMenuOpen('/reports/access')"
				*ngIf="hasDic(Dicionario.RELATORIO_DE_ACESSO)">
				<sidenav-router-link
					route="/reports/access/history"
					[active]="isActive2('/reports/access/history')">
					{{ 'menu.relatorio.historico' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/audit"
					[active]="isActive2('/reports/access/audit')">
					{{ 'menu.relatorio.auditoria' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/card"
					[active]="isActive2('/reports/access/card')">
					{{ 'menu.relatorio.cartao' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/command"
					[active]="isActive2('/reports/access/command')">
					{{ 'menu.relatorio.comando' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/login"
					[active]="isActive2('/reports/access/login')">
					{{ 'menu.relatorio.login' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/blocked"
					[active]="isActive2('/reports/access/blocked')">
					{{ 'menu.relatorio.bloqueio' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/refectory"
					[active]="isActive2('/reports/access/refectory')">
					{{ 'menu.relatorio.refeicao' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/access/infrequent"
					[active]="isActive2('/reports/access/infrequent')">
					{{ 'menu.relatorio.acesso_infrequente' | translate }}
				</sidenav-router-link>
			</sidenav-menu>

			<sidenav-menu
				text="{{ 'menu.cadastrais' | translate }}"
				[active]="isMenuActive('/reports/registrations')"
				[open]="isMenuOpen('/reports/registrations')"
				*ngIf="hasDic(Dicionario.RELATORIO_CADASTRAL)">
				<sidenav-router-link
					route="/reports/registrations/card"
					[active]="isActive2('/reports/registrations/card')">
					{{ 'menu.relatorio.cartao' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/registrations/people"
					[active]="isActive2('/reports/registrations/people')">
					{{ 'menu.relatorio.pessoa' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/registrations/phone"
					[active]="isActive2('/reports/registrations/phone')">
					{{ 'menu.relatorio.telefone' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/registrations/vehicle"
					[active]="isActive2('/reports/registrations/vehicle')">
					{{ 'menu.relatorio.veiculo' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/registrations/visitor"
					[active]="isActive2('/reports/registrations/visitor')">
					{{ 'menu.relatorio.visitante' | translate }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/registrations/local"
					[active]="isActive2('/reports/registrations/local')">
					{{ 'menu.relatorio.localpessoas' | translate }}
				</sidenav-router-link>
			</sidenav-menu>

			<sidenav-menu
				text="{{ 'menu.estatisticas' | translate }}"
				[active]="isMenuActive('/reports/estatisticas')"
				[open]="isMenuOpen('/reports/estatisticas')"
				*ngIf="hasDic(Dicionario.RELATORIO_ESTATISTICA)">
				<sidenav-router-link
					route="/reports/estatisticas/tag_setor"
					[active]="isActive2('/reports/estatisticas/tag_setor')">
					{{ 'menu.relatorio.tag_setor' | translate: {value: translateSetor.toLowerCase()} }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/estatisticas/tag_empresa"
					[active]="isActive2('/reports/estatisticas/tag_empresa')">
					{{ 'menu.relatorio.tag_empresa' | translate: {value: translateEmpresa.toLowerCase()} }}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/estatisticas/qtd_pessoa_empresa"
					[active]="isActive2('/reports/estatisticas/qtd_pessoa_empresa')">
					{{
						'menu.relatorio.qtd_pessoa_empresa' | translate: {value: translateEmpresa.toLowerCase()}
					}}
				</sidenav-router-link>
				<sidenav-router-link
					route="/reports/estatisticas/qtd_pessoa_setor"
					[active]="isActive2('/reports/estatisticas/qtd_pessoa_setor')">
					{{ 'menu.relatorio.qtd_pessoa_setor' | translate: {value: translateSetor.toLowerCase()} }}
				</sidenav-router-link>
			</sidenav-menu>

			<!-- <sidenav-router-link route="/reports/unidades_ocupadas" [active]="isActive2('/reports/unidades_ocupadas')">
        {{'menu.unidades_ocupadas' | translate}}
      </sidenav-router-link> -->
		</sidenav-menu>

		<!-- <sidenav-divider class="mb-1"></sidenav-divider> -->

		<sidenav-divider class="mb-1" *ngIf="isShowConfiguracoes()"></sidenav-divider>
		<sidenav-header class="small font-weight-semibold" *ngIf="isShowConfiguracoes()">
			OUTROS</sidenav-header
		>
		<!--
    <sidenav-router-link route="/config/centralrelatorio/list" icon="fas fa-bullhorn" *ngIf="isShowCentralRelatorio()"
      [active]="isActive2('/config/centralrelatorio')">
      Central do Relatório
    </sidenav-router-link> -->

		<!-- Config -->
		<sidenav-menu
			icon="ion ion-ios-construct"
			text="Configurações"
			[active]="isMenuActive('/config')"
			[open]="isMenuOpen('/config')"
			*ngIf="isShowConfiguracoes()">
			<sidenav-router-link
				*ngIf="hasDic(Dicionario.CENTRAL_RELATORIO)"
				route="/config/centralrelatorio/list"
				[active]="isActive2('/config/centralrelatorio')">
				Central do relatório</sidenav-router-link
			>

			<sidenav-router-link
				*ngIf="hasDic(Dicionario.PLANO)"
				route="/config/plano/list"
				[active]="isActive2('/config/plano')">
				{{ 'menu.plano' | translate }}</sidenav-router-link
			>

			<sidenav-router-link
				*ngIf="hasDic(Dicionario.QUADRO_AVISO)"
				route="/config/mensagem/list"
				[active]="isActive2('/config/mensagem')"
				>Quadro de Avisos</sidenav-router-link
			>

			<sidenav-router-link
				*ngIf="hasDic(Dicionario.GRUPO)"
				route="/config/groupaccount/list"
				[active]="isActive2('/config/groupaccount')">
				{{ 'menu.grupo' | translate }}</sidenav-router-link
			>

			<sidenav-router-link
				*ngIf="hasDic(Dicionario.MODELOEMAILMARKETING)"
				route="/config/modeloemailmarketing/list"
				[active]="isActive2('/config/modeloemailmarketing')">
				{{ 'menu.modeloemailmarketing' | translate }}</sidenav-router-link
			>

			<sidenav-router-link
				*ngIf="hasDic(Dicionario.EMPRESA)"
				route="/config/empresa/list"
				[active]="isActive2('/config/empresa')">
				Empresas</sidenav-router-link
			>

			<!-- <sidenav-router-link *ngIf="hasDic(Dicionario.CONFIGURACAO_SISTEMA)" route="/config/system/edit"
        [active]="isActive2('/config/system/edit')">
        {{ 'menu.sistema' | translate }}</sidenav-router-link> -->
		</sidenav-menu>

		<sidenav-divider class="mb-1"></sidenav-divider>
	</div>
</sidenav>

<ng-template #popoverInicial>
	<div class="d-flex flex-column">
		<p>No dashboard você visualiza as informações gerais.</p>
		<div class="d-flex justify-content-end w-37">
			<button class="btn custom-btn" (click)="nextPopoverInicial()">Próximo</button>
		</div>
	</div>
</ng-template>

<ng-template #popoverStep2>
	<div class="d-flex flex-column">
		<p style="margin-bottom: 0 !important">Aqui você pode visualizar os relatórios de acordo</p>
		<p>com a sua escolha.</p>
		<div class="d-flex justify-content-end w-37">
			<button
				class="btn custom-btn"
				id="clickPopoverStep2"
				#clickPopoverStep2
				(click)="nextPopoverStep2()">
				Próximo
			</button>
		</div>
	</div>
</ng-template>
