import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ValidateBrService } from 'angular-validate-br'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { DefaultSwalModalComponent } from 'src/app/others-component/default-swal-modal/default-swal-modal.component'
import { ApiService } from 'src/app/service/api.service'
import { CompromissoService } from 'src/app/service/compromisso.service'
import { TipoTitularInclusaoVidas, TipoValidoOuInvalido } from 'src/app/utils/enums'
import { retornarMsgErroPorStatus } from 'src/app/utils/util'
import { DadosInclusaoVidas, EmpresaSelect } from '../../model/dados-inclusao-vidas'
import { OpcoesGrauParentesco, OpcoesTipoSexo, OpcoesTipoVida, patternCaracteresEspeciaiseEspaco } from 'src/app/model/vidas/vidas.enum'

@Component({
	selector: 'app-inclusao-vida-avulsa-form-vida-segunda',
	templateUrl: './inclusao-vida-avulsa-form-vida-segunda.component.html',
	styleUrls: ['./inclusao-vida-avulsa-form-vida-segunda.component.scss'],
})
export class InclusaoVidaAvulsaFormVidaSegundaComponent implements OnInit, AfterViewInit {

	opcoesTipoVida = OpcoesTipoVida
	opcoesTipoSexo = OpcoesTipoSexo
	opcoesGrauParentesco = OpcoesGrauParentesco

	opcoesTitulares: []
	opcoesEmpresa: EmpresaSelect[]
	tipoVidaEnum = TipoTitularInclusaoVidas
	tipoVida: string = ''
	buscandoCPF: boolean = false
	buscandoEmpresa: boolean = false
	buscandoColaboradoresTitulares: boolean = false
	beneficiarioJaCadastrado: boolean = false

	@Input() dadosInclusaoVidas: DadosInclusaoVidas

	@ViewChild('swalBeneficiarioJaCadastrado') swalBeneficiarioJaCadastrado: DefaultSwalModalComponent
	@Input() formSegundaInclusao: FormGroup

	get getFormSegundaInclusao() {
		return this.formSegundaInclusao.controls
	}

	constructor(
		private compromissoService: CompromissoService,
		private validateBrService: ValidateBrService,
		public translateService: TranslateService,
		private apiService: ApiService,
	) { }

	ngOnInit() {
		if (!this.formSegundaInclusao) {
			this.formSegundaInclusao = new FormGroup({
				vida_tipo: new FormControl({ value: null, disabled: true }, Validators.required),
				vida_empresa: new FormControl({ value: null, disabled: true }, [Validators.required]),
				vida_nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
				vida_cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
				vida_nomemae: new FormControl(null, [Validators.required]),
				vida_nascimento: new FormControl(null, [Validators.required]),
				vida_sexo: new FormControl(null, [Validators.required]),
				vida_telefone: new FormControl(null, [Validators.required]),
				vida_email: new FormControl(null, [Validators.email]),
				vida_idcompromisso: new FormControl(null, [Validators.required]),
				vida_matricula: new FormControl(null),
				cli_grauparentesco: new FormControl(null, [Validators.required]),
				cli_codigoassociados4e: new FormControl({ value: null, disabled: true }),
				nome_associado_s4e: new FormControl({ value: null, disabled: true }),
				fk_idtitular: new FormControl(null),
				cli_cpf_titular: new FormControl(null)
			})

			this.listarBeneficiarioPorNomeOuCpf(this.dadosInclusaoVidas.nomeTitular)
			this.formSegundaInclusao.enable()
			this.formSegundaInclusao.reset({
				vida_tipo: this.opcoesTipoVida[1],
				cli_codigoassociados4e: { value: this.dadosInclusaoVidas.codAssociadoS4e, descricao: this.dadosInclusaoVidas.nomeTitular },
				nome_associado_s4e: this.dadosInclusaoVidas.nomeTitular,
				fk_idtitular: this.dadosInclusaoVidas.idTitular,
				vida_empresa: { value: this.dadosInclusaoVidas.idEmpresa, descricao: this.dadosInclusaoVidas.nomeEmpresa },
				vida_idcompromisso: this.dadosInclusaoVidas.idCompromisso
			})
			this.formSegundaInclusao.get('vida_tipo')?.disable()
			this.formSegundaInclusao.get('cli_codigoassociados4e')?.disable()
			this.formSegundaInclusao.get('nome_associado_s4e')?.disable()
		}
	}

	ngAfterViewInit() {
		this.getFormSegundaInclusao.vida_cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(status => {
				if (status === TipoValidoOuInvalido.VALID) {
					const formatarCpf = this.getFormSegundaInclusao.vida_cpf.value.replace(
						patternCaracteresEspeciaiseEspaco,
						''
					)
					this.buscarBeneficiarioPorEmpresaS4ECpf(this.dadosInclusaoVidas.idEmpresaS4e, formatarCpf)
				}
			})
	}

	async buscarBeneficiarioPorEmpresaS4ECpf(codEmpresaS4E: number, cpf: string) {
		this.buscandoCPF = true
		const { count = 0 } =
			await this.compromissoService.listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(
				codEmpresaS4E,
				cpf
			)
		if (count) {
			this.swalBeneficiarioJaCadastrado.openModal()
		} else {
			this.beneficiarioJaCadastrado = false
		}
		this.buscandoCPF = false
	}

	listarBeneficiarioPorNomeOuCpf = async (filtro: string) => {
		try {
			this.opcoesTitulares = []
			if (filtro && filtro.length > 2) {
				this.buscandoColaboradoresTitulares = true
				this.opcoesTitulares = (
					await this.compromissoService.listarBeneficiarioPorNomeOuCpf({ search: filtro })
				).map(titular => ({
					value: titular.codigoAssociado,
					descricao: titular.nome + ' - ' + titular.razaoSocial,
					cpf: titular.cpf,
					idEmpresaS4e: titular.idEmpresaS4e,
					idEmpresa: titular.idEmpresa
				}))
			}
		} catch (error) {
			this.apiService.showToast(null, retornarMsgErroPorStatus(error), 'error')
		} finally {
			this.buscandoColaboradoresTitulares = false
		}
	}

	fecharSwalBeneficiarioJaCadastrado() {
		this.beneficiarioJaCadastrado = true
		this.getFormSegundaInclusao.vida_cpf.setValue(null)
	}
}
