import { Dicionario } from './dicionario';
type CRUD = "C" | "R" | "U" | "D"

export class Login {
    configuracao_atual: any;
    conta: any;
    expiresInHours: number;
    gru_configuracao: any;
    grupopermissoes: any;
    token: string;
    sessionExpired: boolean = false;
    idcliente_remote: any = null;
    cli_modoconexao: any = null;


    constructor(responseAPI: any) {

        this.conta = responseAPI.conta || responseAPI;
        this.expiresInHours = responseAPI.expiresInHours;
        this.token = responseAPI.token;

        try {
            this.configuracao_atual = JSON.parse(this.conta.csi_configuracao);
        } catch (error) {
            console.log('csi_configuracao: ' + error);
        }

        try {
            if (typeof this.conta.grupopermissoes === 'string' || this.conta.grupopermissoes instanceof String) {
                this.grupopermissoes = JSON.parse(this.conta.grupopermissoes)
            } else {
                this.grupopermissoes = this.conta.grupopermissoes
            }

        } catch (error) {
            console.log('grupopermissoes: ' + error);
        }

        try {
            this.gru_configuracao = JSON.parse(this.conta.gru_configuracao);
        } catch (error) {
            console.log('gru_configuracao: ' + error);
        }


    }

    get nomeCliente() {
        return this.conta.cli_nome;
    }

    get idConta() {
        return this.conta.pk_idconta;
    }

    get login() {
        return this.conta.con_email;
    }

    get nomeConta() {
        return this.conta.con_nome;
    }

    get nomeGrupo() {
        return this.conta.gru_nome;
    }

    get idGrupo() {
        return this.conta.fk_idgrupo;
    }

    get isAdm() {
        return (this.conta.gru_nome == 'ADMINISTRADOR' || this.conta.gru_nome == 'MASTER') ? 1 : 0
    }

    get isSalesAdm() {
        return (this.conta.gru_nome == 'ADMINISTRADOR' || this.conta.gru_nome == 'MASTER' || this.conta.gru_nome == 'ADMINISTRADOR SALES') ? 1 : 0
    }

    get isFieldSalesOrAccount() {
        return (this.conta.gru_nome == 'FIELD SALES' || this.conta.gru_nome == 'ACCOUNT EXECUTIVE' || this.conta.gru_nome == 'FIELD SALES MASSIFICADO') ? 1 : 0
    }

    get isMaster() {
        return ( this.conta.gru_nome == 'MASTER') ? 1 : 0
    }

    get isAccountExec() {
        return (this.conta.gru_nome == 'ACCOUNT EXECUTIVE') ? 1 : 0
    }

    get isSalesRep() {
        return (this.conta.gru_nome == 'SALES REP') ? 1 : 0
    }

    get isFieldSales() {
        return (this.conta.gru_nome == 'FIELD SALES' || this.conta.gru_nome == 'FIELD SALES MASSIFICADO') ? 1 : 0
    }

    temPermissao(dicionario: Dicionario, crud: CRUD): boolean {
        var retorno = false;

        if (dicionario && this.grupopermissoes) {

            this.grupopermissoes.forEach(gp => {

                if (gp.fk_iddicionariotabela == dicionario && gp.gpe_acesso && gp.gpe_acesso.indexOf(crud) >= 0) {
                    retorno = true;
                    return;
                }

            });
        }

        return retorno;

    }

    getConfiguracaoPorPefilPessoa(perfil): any {

        let retorno = null;



        return retorno;
    }

	  getEmailUser():string {
	  	const data = localStorage.getItem('remember_login')
	  		? JSON.parse(localStorage.getItem('remember_login'))
	  		: null
	  	return data?.con_email
	  }
}
