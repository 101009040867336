<div
	class="custom-simple-select"
	[ngClass]="{
		'is-invalid': ngControl?.control?.touched && ngControl?.control?.errors?.required
	}">
	<div class="d-flex flex-row">
		<div>
			<label class="form-label pb-1">
				{{ selectUpperLabel }}
			</label>
		</div>
	</div>
	<div *ngIf="iconClass" class="input-group">
		<ng-select
			#simpleSelect
			[disabled]="ngControl?.control?.disabled"
			loadingText="{{ 'progress.loading' | translate }}"
			[placeholder]="placeholder"
			[notFoundText]="notFoundText"
			[clearAllText]="clearAllText"
			[searchable]="searchable"
			[multiple]="false"
			[closeOnSelect]="closeOnSelect"
			[hideSelected]="hideSelected"
			[typeToSearchText]="typeToSearchText"
			[clearable]="clearable"
			[clearOnBackspace]="false"
			[typeahead]="selectTypeahead"
			[id]="name"
			[bindLabel]="bindLabel"
			[bindValue]="bindValue"
      		alternativeLabel="alternativeLabel"
			[items]="items"
			[loading]="loading"
			[readonly]="isReadonly"
			(clear)="clearSelection()"
			[virtualScroll]="virtualScroll"
			[(ngModel)]="value"
			(scrollToEnd)="scrollToEnd($event?.target?.value)"
			(change)="onChangeSelection($event)"
			[compareWith]="compareFn"
			(scroll)="onScroll($event, $event?.target?.value)">
			<ng-template ng-label-tmp let-item="item">
				<div class="d-flex flex-row mr-1">
					<span class="ellipsis-format mr-2">{{ item[bindLabel] }}</span>
        <span *ngIf="alternativeLabel">{{ item[alternativeLabel ]}}</span>
				</div>
			</ng-template>
			<ng-template ng-option-tmp let-item="item">
				<div class="d-flex flex-row mr-1">
					<span class="ellipsis-format mr-2">{{ item[bindLabel] }}</span>
        <span *ngIf="alternativeLabel">{{ item[alternativeLabel ]}}</span>
				</div>
			</ng-template>
		</ng-select>
		<div class="input-group-append">
			<button class="input-group-text" disabled>
				<span class="{{ iconClass }}"></span>
			</button>
		</div>
	</div>

	<ng-select
		*ngIf="!iconClass"
		#simpleSelect
		[disabled]="ngControl?.control?.disabled"
		loadingText="{{ 'progress.loading' | translate }}"
		[placeholder]="placeholder"
		[notFoundText]="notFoundText"
		[clearAllText]="clearAllText"
		[searchable]="searchable"
		[multiple]="false"
		[typeToSearchText]="typeToSearchText"
		[closeOnSelect]="closeOnSelect"
		[hideSelected]="hideSelected"
		[clearable]="clearable"
		[typeahead]="selectTypeahead"
		[clearOnBackspace]="false"
		[id]="name"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
    	alternativeLabel="alternativeLabel"
		[items]="items"
		[loading]="loading"
		[readonly]="isReadonly"
		(clear)="clearSelection()"
		[(ngModel)]="value"
		[virtualScroll]="virtualScroll"
		[compareWith]="compareFn"
		(scrollToEnd)="scrollToEnd($event?.target?.value)"
		(change)="onChangeSelection($event)"
		(scroll)="onScroll($event, $event?.target?.value)">
		<ng-template ng-label-tmp let-item="item">
      <div class="d-flex flex-row mr-1">
        <span class="ellipsis-format mr-2">{{ item[bindLabel] }}</span>
      <span *ngIf="alternativeLabel">{{ item[alternativeLabel ]}}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="d-flex flex-row mr-1">
        <span class="ellipsis-format mr-2">{{ item[bindLabel] }}</span>
      <span *ngIf="alternativeLabel">{{ item[alternativeLabel ]}}</span>
      </div>
    </ng-template>
	</ng-select>

	<div
		*ngIf="ngControl?.control?.touched && ngControl?.control?.errors?.required"
		class="mt-2 error">
		Campo obrigatório
	</div>
</div>
