import { CurrencyPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { PlanoEmpresa } from 'src/app/model/interfaces/inclusao-vida-popup'
import { RadioOption } from 'src/app/model/interfaces/radio-option'

@Component({
	selector: 'app-migrar-plano-form-plano',
	templateUrl: './migrar-plano-form-plano.component.html',
	styleUrls: ['./migrar-plano-form-plano.component.scss'],
	providers: [CurrencyPipe],
})
export class MigrarPlanoFormPlanoComponent implements OnInit {

	opcoesPlanoRadioGroup: RadioOption[] = []
	@Input() listaProdutos: PlanoEmpresa[]
	@Input() idPlanoBeneficiario: number
	planoAtual: PlanoEmpresa

	@Input() formMigrarPlano: FormGroup

	public get getFormMigrarPlano() {
		return this.formMigrarPlano.controls
	}

	constructor(private currencyPipe: CurrencyPipe,
		public translateService: TranslateService) { }

	ngOnInit() {

		if (!this.formMigrarPlano) {
			this.formMigrarPlano = new FormGroup({
				idPlanoAtual: new FormControl(null, [Validators.required]),
				idNovoPlano: new FormControl(null, [Validators.required])
			})
		}

		if (this.listaProdutos && this.idPlanoBeneficiario) {
			this.getFormMigrarPlano.idPlanoAtual.setValue(this.idPlanoBeneficiario)
			this.planoAtual = this.listaProdutos.find(produto => produto.idPlano === this.idPlanoBeneficiario)
			this.opcoesPlanoRadioGroup = this.listaProdutos
				.filter(plano => plano.idPlano !== this.planoAtual?.idPlano)
				.map(plano =>
				({
					value: plano.idPlano,
					label: plano.plano.nome,
					description: `(Titular:  ${this.currencyPipe.transform(
						plano.valorTitular,
						'BRL'
					)} | Dependente: ${this.currencyPipe.transform(plano.valorDependente, 'BRL')})`
				}))
		}
	}

	produtoSelecionado(produtoSelecionado: RadioOption) {
		this.formMigrarPlano.patchValue({
			idNovoPlano: produtoSelecionado.value
		})
	}
}
