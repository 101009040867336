import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {PublicoService} from '../service/publico.service'
import {ApiService} from '../service/api.service'
import {BlipService} from '../service/blip.service'

@Component({
	selector: 'app-manutencao',
	templateUrl: './manutencao.component.html',
	styleUrls: ['./manutencao.component.scss'],
})
export class ManutencaoComponent implements OnInit {
	numeroEspecialistaCCT = ''
	sindicatoInfo: any

	constructor(
		private route: ActivatedRoute,
		private publicService: PublicoService,
		private apiService: ApiService,
		private router: Router,
		private blipService: BlipService,
	) {}

	ngOnInit() {
		this.carregarDados(this.route.snapshot.paramMap.get('paf'))
	}

	falarComEspecialista() {
		const msg = `Olá, estou com algumas dúvidas enquanto acesso a página da CCT do Sindicato ${this.sindicatoInfo?.nomeFantasia || this.sindicatoInfo?.razaoSocial || ''}`

		if (!this.blipService.checkIfHasBlipChatOpened()) {
			this.blipService.setInitialMessage(msg)
		}

		this.blipService.openBlip()
	}

	async carregarDados(pafCode: string) {
		this.publicService
			.getInfoByPaf(pafCode)
			.then((res: any) => {
				this.numeroEspecialistaCCT = res.contatoEspecialista
				this.sindicatoInfo = res.sindicatoPatronal
			})
			.catch(async (err: any) => {
				if (err?.error?.message) {
					this.apiService.showToast(null, err?.error?.message, 'error')
				} else {
					this.apiService.showToast(null, 'Nenhuma CCT encontrada com este link.', 'error')
				}
			})
	}
}
