import { CurrencyPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { PlanoEmpresa } from 'src/app/model/interfaces/inclusao-vida-popup'
import { RadioOption } from 'src/app/model/interfaces/radio-option'

@Component({
	selector: 'app-inclusao-vida-avulsa-form-plano',
	templateUrl: './inclusao-vida-avulsa-form-plano.component.html',
	styleUrls: ['./inclusao-vida-avulsa-form-plano.component.scss'],
	providers: [CurrencyPipe]
})
export class InclusaoVidaAvulsaFormPlanoComponent implements OnInit {

	opcoesPlanoRadioGroup: RadioOption[] = []
	@Input() listaProdutos: PlanoEmpresa[] = []
	@Input() formSelecaoProdutos: FormGroup

	constructor(
		private currencyPipe: CurrencyPipe,
		public translateService: TranslateService
	) { }

	ngOnInit() {

		if (!this.formSelecaoProdutos) {
			this.formSelecaoProdutos = new FormGroup({
				cco_id_produto_s4_melhorai: new FormControl(null),
				cco_id_produto_s4_agiben: new FormControl(null),
				cco_id_produto_s4: new FormControl(null, [Validators.required])
			})
		}

		if (this.listaProdutos) {
			this.opcoesPlanoRadioGroup = this.listaProdutos.map((produto: any) => ({
				value: produto.idPlano,
				label: produto.plano.nome,
				description: `(Titular: R$ ${this.currencyPipe.transform(
					produto.valorTitular,
					'BRL'
				)} | Dependente: R$ ${this.currencyPipe.transform(produto.valorDependente, 'BRL')})`
			}))
		}
	}

	produtoSelecionado(produtoSelecionado: RadioOption) {
		this.formSelecaoProdutos.patchValue({
			cco_id_produto_s4: produtoSelecionado.value
		})
	}
}
