import {Component, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {ModalWithOneButtonComponent} from '../others-component/modal-with-one-button/modal-with-one-button.component'
import {CctService, Sindicato} from '../service/cct.service'
import {Beneficio} from '../model/cct/beneficios/beneficio.model'
import {TipoProvedor} from '../utils/enums'
import {DadosLandingPageUsuarioCCT} from '../model/cct/beneficios/dados-landing-page-usuario-cct.model'
import {ActivatedRoute, Router} from '@angular/router'
import {TipoSindicatoEnum} from '../model/sindicatos/tipo-sindicato-enum'
import {MixpanelService} from '../service/mixpanel.service'
import {MixPanelEvents} from '../utils/mixpanel-events'
import {PropriedadesMixPanelLP} from '../model/cct/beneficios/landing-page/interfaces'
import {PublicoService} from '../service/publico.service'
import {ApiService} from '../service/api.service'
import {CctActStatusRaw} from '../model/cct/cct-act-status.enum'
import {BlipService} from '../service/blip.service'

@Component({
	selector: 'landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit {
	iconeSindicatoUrl = ''

	pafCode: string
	idCCT: number | undefined
	idSindicato: number
	combinacaoSindicatoSelecionadoLabel = ''

	ilustracaoCCTUrl = 'assets/img/logo/novo-banner-agiben.png'
	logoCCTUrl = 'assets/img/logo/sorriso_agiben.svg'
	messageCCTInvalid = 'A fase da cct está inválida para esta operação'

	propsMixpanelLP: PropriedadesMixPanelLP = {
		cnpj: '',
		id: 0,
		razaoSocial: '',
		idCCT: 0,
		nome: '',
	}

	numeroEspecialistaCCT = ''

	beneficiosCardPAF = []

	listaBeneficios: Beneficio[] = []
	listaSindicatos: Sindicato[] = []
	listaSindicatosPatronais: Sindicato[] = []
	sindicatoInfo: any = {}

	valorPAFTitularInteiro = ''
	valorPAFTitularDecimal = ''
	informacoesBanner = ''
	linkDinamicoCCT
	modalSelecaoSindicatoAberto = false

	dadosGerais: DadosLandingPageUsuarioCCT

	constructor(
		private modalService: NgbModal,
		private cctService: CctService,
		private router: Router,
		private route: ActivatedRoute,
		private mixpanelService: MixpanelService,
		private publicService: PublicoService,
		private apiService: ApiService,
		private renderer: Renderer2,
		private blipService: BlipService,
	) {}

	ngOnInit(): void {
		this.pafCode = this.route.snapshot.paramMap.get('paf')

		if (this.pafCode?.toUpperCase() === 'PAF-COMERCIARIOS-PETROLINA') {
			this.addScriptPipeDrive()
		}

		this.publicService
			.getInformacoesCCTPeloPaf(this.pafCode)
			.then((res: any) => {
				this.idCCT = res?.cctId || null

				const fase = res?.fase
				const status = res?.status

				if (fase === CctActStatusRaw.IMPLANTADO && status === CctActStatusRaw.IMPLANTADO) {
					this.redirectToPath(`/${this.pafCode}`)
					const sindicatoPatronal = res.sindicatos.find(
						sindicato => sindicato.tipo === TipoSindicatoEnum.PATRONAL,
					)
					this.idSindicato = sindicatoPatronal.id
					this.carregarDados()
				}
			})
			.catch(async (err: any) => {
				const isRedirect = {redirect: true}
				if (err?.error?.message) {
					this.apiService.showToast(null, err?.error?.message, 'error')
					if (err?.error?.message === this.messageCCTInvalid) {
						isRedirect.redirect = false
						this.redirectToPath(`/manutencao/${this.pafCode}`)
					}
				} else {
					this.apiService.showToast(null, 'Nenhuma CCT encontrada com este link.', 'error')
				}
				if (isRedirect.redirect) {
					await new Promise(f => setTimeout(f, 2500))
					this.redirectToPath('/')
				}
			})
	}

	async abrirModalSelecaoSindicato() {
		const options: NgbModalOptions = {
			windowClass: 'modal-md animate nested-modal',
			backdrop: 'static',
			keyboard: false,
		}

		this.listaSindicatos = await this.cctService.listarSindicatos(this.idCCT)
		const sindicatoLaboral = this.listaSindicatos.find(
			sindicato => sindicato.tipoSindicato === TipoSindicatoEnum.LABORAL,
		)

		this.listaSindicatosPatronais = this.listaSindicatos.filter(
			sindicato => sindicato.tipoSindicato !== TipoSindicatoEnum.LABORAL,
		)

		if (this.listaSindicatosPatronais.length > 1) {
			const modal = this.modalService.open(ModalWithOneButtonComponent, options)
			modal.componentInstance.sindicatos = this.listaSindicatosPatronais
			modal.componentInstance.title = sindicatoLaboral?.nomeFantasia.toUpperCase()

			modal.result.then(idSindicato => {
				if (idSindicato && this.idCCT) {
					this.idSindicato = idSindicato
					this.carregarDados()
					this.modalSelecaoSindicatoAberto = false
				}
			})
		} else if (this.listaSindicatosPatronais.length === 1) {
			this.idSindicato = this.listaSindicatosPatronais[0].id
			this.carregarDados()
			this.modalSelecaoSindicatoAberto = false
		} else if (sindicatoLaboral) {
			this.idSindicato = sindicatoLaboral.id
			this.carregarDados()
			this.modalSelecaoSindicatoAberto = false
		} else {
			const modal = this.modalService.open(ModalWithOneButtonComponent, options)
			modal.componentInstance.title = 'Nenhum sindicato cadastrado nesta CCT.'
			this.modalSelecaoSindicatoAberto = false

			modal.result.then(() => {
				this.redirectToPath('/')
				this.modalSelecaoSindicatoAberto = false
			})
		}
	}

	baixarCCT(origem: string) {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_BAIXAR_CCT, {
			...this.propsMixpanelLP,
			localizacao: origem,
			numero: this.numeroEspecialistaCCT,
		})

		window.open(this.linkDinamicoCCT, '_blank').focus()
	}

	cadastrarMinhaEmpresa(origem: string) {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_CADASTRAR_MINHA_EMPRESA, {
			...this.propsMixpanelLP,
			localizacao: origem,
			numero: this.numeroEspecialistaCCT,
		})

		this.redirectCadastrarEmpresaSindicato(this.idCCT, this.idSindicato)
	}

	clicouLogoAgiben(idSecao: string) {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_NAVBAR_OPCAO, {
			...this.propsMixpanelLP,
			localizacao: 'NAVBAR',
			secao: idSecao,
		})

		if (idSecao) {
			document.getElementById(idSecao).scrollIntoView({
				behavior: 'smooth',
			})
		}
	}

	async carregarDados() {
		this.cctService
			.buscarInformacoesCCTPorIdESindicatoId(this.idCCT, this.idSindicato)
			.then((result: DadosLandingPageUsuarioCCT) => {
				this.dadosGerais = result

				this.sindicatoInfo = this.dadosGerais?.sindicato
				this.ilustracaoCCTUrl = this.dadosGerais?.sindicato?.imagemUrl
					? this.dadosGerais?.sindicato?.imagemUrl
					: this.ilustracaoCCTUrl
				this.logoCCTUrl = this.sindicatoInfo?.logoUrl
					? this.sindicatoInfo?.logoUrl
					: this.logoCCTUrl

				this.linkDinamicoCCT = this.dadosGerais?.sindicato.linkCCT
				this.valorPAFTitularInteiro = Math.trunc(this.dadosGerais?.valorPAFTitular).toString()
				this.valorPAFTitularDecimal = (
					this.dadosGerais?.valorPAFTitular - Math.floor(this.dadosGerais?.valorPAFTitular)
				)
					.toFixed(2)
					.substring(2)

				this.numeroEspecialistaCCT = this.dadosGerais?.contatoEspecialista

				this.informacoesBanner = this.sindicatoInfo.informacoes

				this.propsMixpanelLP = {
					id: this.dadosGerais?.sindicato.id,
					nome: this.dadosGerais?.nome,
					idCCT: this.dadosGerais?.id,
					razaoSocial: this.dadosGerais?.sindicato.razaoSocial,
					cnpj: this.dadosGerais?.sindicato.cnpj,
				}

				this.mixpanelService.track(
					MixPanelEvents.LP_SINDICATO_MODAL_SELECAO_SINDICATOS_CLIQUE_SELECIONAR_SINDICATO,
					{
						...this.propsMixpanelLP,
					},
				)

				if (this.listaSindicatosPatronais.length > 1) {
					this.combinacaoSindicatoSelecionadoLabel = `${this.sindicatoInfo.laboral?.nomeFantasia} / ${this.sindicatoInfo.nomeFantasia}`
				}

				this.beneficiosCardPAF = []

				this.listaBeneficios = this.dadosGerais?.beneficios.map(beneficio => {
					this.incluirBeneficiosCardPAF(beneficio)

					const topicos = beneficio.topicos
					return {
						...beneficio,
						topicos: topicos.map((topico, index) => {
							return {...topico, ativo: index === 0 ? true : false}
						}),
					}
				})
			})
	}

	clicouSelecionarSindicatoPatronal() {
		if (!this.modalSelecaoSindicatoAberto) {
			this.modalSelecaoSindicatoAberto = true
			this.abrirModalSelecaoSindicato()
		}
	}

	clickEmLinkDinamicoCCT(event) {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_BAIXAR_CCT, {
			...this.propsMixpanelLP,
			nomeBeneficio: event.beneficio.titulo,
			idBeneficio: event.beneficio.id,
			nomeTopico: event.topicoSelecionado.titulo,
			idTopico: event.topicoSelecionado.id,
			localizacao: 'SECAO-BENEFICIO',
		})
	}

	topicoBeneficioSelecionado(event) {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_ABA_BENEFICIO_TOPICO, {
			...this.propsMixpanelLP,
			nomeBeneficio: event.beneficio.titulo,
			idBeneficio: event.beneficio.id,
			nomeTopico: event.topicoSelecionado.titulo,
			idTopico: event.topicoSelecionado.id,
			localizacao: 'SECAO-BENEFICIO',
		})
	}

	falarComEspecialista() {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_FALAR_COM_ESPECIALISTA, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
			numero: this.numeroEspecialistaCCT,
		})

		const msg = `Olá, estou com algumas dúvidas enquanto acesso a página da CCT do Sindicato ${this.sindicatoInfo?.nomeFantasia || this.sindicatoInfo?.razaoSocial || ''}`

		if (!this.blipService.checkIfHasBlipChatOpened()) {
			this.blipService.setInitialMessage(msg)
		}

		this.blipService.openBlip()
	}

	clicouAbrirSite() {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_ABRIR_SITE, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
		})
	}

	clicouAbrirInstagram() {
		this.mixpanelService.track(MixPanelEvents.LP_SINDICATO_CLIQUE_ABRIR_INSTAGRAM, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
		})
	}

	incluirBeneficiosCardPAF(beneficio) {
		switch (beneficio.tipoProvedor) {
			case TipoProvedor.CLIN:
				this.beneficiosCardPAF.push('Odontologia')
				break
			case TipoProvedor.MELHORAI:
				this.beneficiosCardPAF.push('Telemedicina')
				break
			case TipoProvedor.AGIBEN:
				this.beneficiosCardPAF.push('Assistências')
				break
		}
	}

	private redirectToPath(path: string): void {
		this.router.navigate([path])
	}

	private redirectCadastrarEmpresaSindicato(idCCT: number, idSindicato: number) {
		this.router.navigate(['/cadastro-empresa-sindicato/cct'], {
			queryParams: {
				id: idCCT,
				sind: idSindicato,
			},
		})
	}

	/**
	 * Função que adiciona o script do PipeDrive para os acessos a esta página caírem como Lead para Breno
	 */
	private addScriptPipeDrive() {
		const script = this.renderer.createElement('script')
		script.text = `
		  (function(ss,ex){
			window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));};
			(function(d,s){
			  fs=d.getElementsByTagName(s)[0];
			  function ce(src){
				var cs=d.createElement(s);
				cs.src=src;
				cs.async=1;
				fs.parentNode.insertBefore(cs,fs);
			  };
			  ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');
			})(document,'script');
		  })('YEgkB8lKXjvaep3Z');
		`
		this.renderer.appendChild(document.body, script)
	}

	navegarParaPaginaDoTrabalhador() {
		const idCCT = this.idCCT
		const idSindicato = this.idSindicato

		this.router.navigate([`landing-page-usuario/cct/${idCCT}/sindicato/${idSindicato}`])
	}
}
